import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './modules/bookings/calendar/calendar.component';
import { AuthGuard } from './modules/auth/_services/auth.guard';
// import {ListOfReservationsComponent} from './modules/list-of-reservations/list-of-reservations.component'

export const routes: Routes = [
  // { path: 'list-of-reservations', component: ListOfReservationsComponent },
  {path:'', redirectTo:'/dashboard', pathMatch:"full"},
  
  {path:'calendar', component:CalendarComponent},

  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'ping', 
    loadChildren: () =>
      import('./ping/ping.module').then((m) => m.PingModule),
  },    
  /*{
    path: 'admin',
    loadChildren: () =>
      import('./modules/admin/admin.module').then((m) => m.adminModule),
  },*/
  {
    path: '',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/layout.module').then((m) => m.LayoutModule),
  },

 

  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
