export class CalendarDatesModel {
    date: Date;
    isWeekEnd: boolean = false;
    bookingItem: any;
    Cid: any;
}

export class BookingsRow {
    bookingDetail: any;
    calendarDates: CalendarDatesModel[] = [];
}





