import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../_services';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr'; 
import { CustomAdapter, CustomDateParserFormatter } from 'src/app/_PMSChicstays/core';
import { BookingsRow, CalendarDatesModel } from './calendar.model';
import { ChangeRoomModalComponent } from './components/change-room-modal/change-room-modal.component';
import { catchError } from 'rxjs/operators';
 
import Pusher from 'pusher-js';
import { setTokenSourceMapRange } from 'typescript';

declare var $: any;
declare var document;


@Component({
	selector: 'app-rooms',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
	providers: [
		{ provide: NgbDateAdapter, useClass: CustomAdapter },
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
	]
})
export class CalendarComponent implements OnInit, OnDestroy {
	// intervalTime = interval(1 * 60000);
	filter_date: string;
	isLoadertrue: boolean = false;
	sanboxloader: boolean = false;
	tableExpend: boolean = true;
	isLoading: boolean;
	isLoading$: Observable<boolean>;
	IsLoading: any;
	isLoadingNew$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	pointernone$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	hotelList: any;
	roomTypesList: any;
	private subscriptions: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
	hotelID: any = 0;
	roomID: any = 0;
	start_room_id: any = 0;
	dateFilter: any;
	dateFilter1: any;
	Todaysdate: any;
	get_access: any;
	bookingsRow: BookingsRow[] = [];
	bookingsRow1: any = [];
	calDate: any = [];
	sandBoxpopupData: any = [];
	bookin_new: any;
	calendarDates: CalendarDatesModel[] = [];
	addBookingDate: any = '';
	addBookingEndDate: any = '';
	departure_date: any = '';
	arrivel_date: any = '';
	addBookingRoom_id: any = '';
	start_roomtype_id: any = 0;
	end_roomtype_id: any = 0;
	urlHotel_id: any = 0;
	urlroom_id: any = 0;
	scrolledWhere: any = '';
	availabD: any = '';
	hotel_id: any = null;
	roomtype_id: any = null;
	temporary_closure: any = '';
	calledChangeRoom: any = '';
	SandselectedDate: any = '';
	Sandselectedhotelid: any = 0;
	restriction_release: any = null;
	modify_access: any = false;
	assignRoomActive: any = false;
	pointernone: boolean = true;
	read_access: any = false;
	isScrolled_once: any = "no";
	ischangedHotel: any = "no";
	restriction_action: any = "";
	restriction_date: any = "";
	urldate: any = "";
	TablellastDate: any = "";
	result: any = [];
	selectedMonth: any = "";
	sandbboxDate: any;
	monthcounter: any = 0;
	monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	restriction_datearr: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
	sidepaneldata: any = [0, 1, 2, 3, 4, 5, 6, 7];
	monthyearArr: any = [];
	datadumy: any = [];
	dataDate: any;
	onHover: boolean = false;
	hoverTo: any;
	scrollWheredCheck: boolean = false;
	scrollCheck: boolean = false;  
	checkpusher: boolean = false;  
	constructor( 
		private modalService: NgbModal,
		public commonService: CommonService,
		private router: Router,
		public config: NgbModalConfig,
		private activatedRoute: ActivatedRoute,
		private renderer2: Renderer2,
		private toastr: ToastrService,
	) {


		// Pusher.logToConsole = true; 
		// var pusher = new Pusher('7c343ec81c47d53afd17', {
		// 	cluster: 'ap2' 
		// });
		// var channel = pusher.subscribe('events');
		// var receivedData = channel.bind('RealTimeBookEntries', function (data) { 
		// 	alert(JSON.stringify(data));  

		// 	var scrolledRightVal = $('.scrollrightBookingDate').val();
		// 	$('.scrollleftBookingDate').val(scrolledRightVal);
		// 	this.dateFilter = scrolledRightVal; 
		// 	alert("1");  
		// 	console.log('Received data:', data);
		// 	this.getBookings(this.hotelID, this.roomID, this.dateFilter); 
		// });



		  // Enable pusher logging - don't include this in production
			Pusher.logToConsole = true;

			var pusher = new Pusher('7c343ec81c47d53afd17', {
				cluster: 'ap2'
			});
		
			var channel = pusher.subscribe('events');
			channel.bind('RealTimeBookEntries', function(data) {
				
				// alert(JSON.stringify(data));
				this.checkpusher = true; 				
			}); 


			// alert(this.checkpusher)
		
		config.backdrop = 'static'; 
		config.keyboard = false;

		this.get_access = this.commonService.get_access();
		if (this.get_access.length) {
			for (let data in this.get_access) {
				if (this.get_access[data].name == "booking") {
					this.read_access = this.get_access[data]?.read;
					this.modify_access = this.get_access[data]?.modify;
				}
			}
		} else {
			this.read_access = true;
			this.modify_access = true;
		}
		if (this.activatedRoute.snapshot.queryParams['hotel_id'] != undefined) {
			this.urlHotel_id = this.activatedRoute.snapshot.queryParams['hotel_id'];
			this.urldate = this.activatedRoute.snapshot.queryParams['date'];
			this.urlroom_id = this.activatedRoute.snapshot.queryParams['room_id'];
		}
		$("table#booking_table tbody tr td").each(function () {
			var resfrom = $(this).next().attr("resfrom").val;
		}); 

	}
	ngOnInit(): void {

		if(this.checkpusher) {

			var scrolledRightVal = $('.scrollrightBookingDate').val();
			$('.scrollleftBookingDate').val(scrolledRightVal);
			this.dateFilter = scrolledRightVal; 


			this.getBookings(this.hotelID, this.roomID, this.dateFilter); 
		}
		// this.intervalTime.subscribe(() => {
		// 	this.scrollCheck = true; 

		// 	var scrolledRight = $('.scrollrightBookingDate').val();
		// 	$('.scrollleftBookingDate').val(scrolledRight);
		// 	this.dateFilter = scrolledRight; 


		// 	setTimeout(() => {									
		// 		$('.oneScrolled').val('0');
		// 		$('.scrolledWhere').val(''); 
		// 		this.calDate = [];
		// 		this.bookingsRow = [];				
		// 	}, 2000);  

		// 	this.getBookings(this.hotelID, this.roomID, this.dateFilter);
		// });
 
		this.filter_date = "test";
		var today = new Date(); // current date
		var month = today.getMonth() + 1;
		var end = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(); // end date of month
		for (let i = 1; i <= end; i++) {
			var Finaldata = { 'date': today.getFullYear() + '-' + month + '-' + (i < 10 ? '0' + i : i), 'available': 0 };
			this.result.push(Finaldata)
		}
		this.datadumy.push({
			'hotel_id': 1,
			'row_type': "hotelname",
			'hotelname': " ",
			'total_availability': this.result,
		})
		if (localStorage.getItem('hotelID')) {
			this.setDateFilter();
			this.onChangeHotel(localStorage.getItem('hotelID'))
		}
		this.create_month_year_arr();
		if (this.read_access == true) {
			var self = this;

			setTimeout(function () {
				self.setStyles();
				self.setDateFilter();
			}, 500);

			self.calledChangeRoom == 'no'
			this.bookingsRow = this.datadumy;
			this.calDate = this.datadumy[0].total_availability;
			setTimeout(function () {
				self.getHotelList();
			}, 500);
			this.isLoading$ = this.commonService.isLoading$;
			const sb = this.commonService.isLoading$.subscribe(res => this.isLoading = res);
			this.subscriptions.push(sb);
			this.isScrolled_once = "no";
			$('.bookingCalendar').on('click', function (e) {
				let className = (e.target.className);
				if (className.indexOf('booking_main_div') == -1 && className.indexOf('booking_inner_div') == -1 && className.indexOf('common_booking_pop') == -1 && className.indexOf('nodisp_none') == -1) {
					$('.dragSelect').removeClass('activeCell').removeClass('radius_left').removeClass('radius_right');
					self.addBookingEndDate = '';
				}
				if (className.indexOf('booking_main_div') == -1 && className.indexOf('booking_inner_div') == -1 && className.indexOf('common_booking_pop') == -1 && className.indexOf('nodisp_none') == -1 && className.indexOf('SelectDragable') == -1) {
					$('.booking_main_div').addClass('display_none');
				}
			});

			let de: any;
			var pickDate = '';
			var room_id = 0;
			var room_id_a = 0;
			var room_id_b = 0;
			var room_name = '';
			var arr_x = '';
			var arr_y = '';
			var cod1: any = '';
			var cod2: any = '';

			var is_initialized = $('.is_initialized').val();
			if (is_initialized == 'yes') {
				$(document).ready(() => {
					$('.is_initialized').val('no');
					$('body').on("dragstart", "div[class*=task]", function (event) {
						if (self.modify_access == false) {
							return false;
						}
						$('.booking_main_div').addClass('display_none');
						$('.dragSelect').removeClass('activeCell').removeClass('radius_left').removeClass('radius_right');
						room_id_a = $(this).attr('room_id');
						cod1 = $(this).parent().attr('ind_x');
						cod2 = $(this).parent().attr('ind_y');
						self.hotel_id = $(this).parent().attr('hotel_id');
						self.roomtype_id = $(this).parent().attr('roomtype_id');
						self.addBookingDate = $(this).parent().attr('ClaeDate');
						self.addBookingRoom_id = room_id_a;
						$(this).parent().addClass('activeCell');
						$(this).parent().addClass('radius_left');
						pickDate = $(this).parent().attr('ClaeDate');

						$('.addHotelbotton').attr('hotel_id', self.hotel_id);
						$('.addHotelbotton').attr('roomtype_id', self.roomtype_id);
						$('.addHotelbotton').attr('addBookingDate', self.addBookingDate);
						$('.addHotelbotton').attr('addBookingRoom_id', self.addBookingRoom_id);
					});

					$('body').on("dragenter dragover drop", "table .emptyTd", function (event) {
						event.preventDefault();
						room_id_b = $(this).attr('room_id');
						$(this).addClass('activeCell');
						if (event.type === "drop") {
							this.pointernone = true;
							$('.dragSelect').removeClass('activeCell');
							let codd2 = parseInt($(this).attr('ind_y'));
							for (let aa = parseInt(cod2); aa <= codd2; aa++) {
								$('.task' + cod1 + aa).parent().addClass('activeCell');
							}
							$(this).addClass('radius_right');
							cod1 = $(this).attr('ind_x');
							cod2 = $(this).attr('ind_y');
							$('.booking_popDts').empty();
							self.addBookingEndDate = $(this).attr('ClaeDate');
							let date = new Date(self.addBookingEndDate);
							let date1: any = new Date(date.getTime());
							date1.setDate(date.getDate() + 1);
							let month = date1.getMonth() + 1;
							self.addBookingEndDate = date1.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (date1.getDate() < 10 ? '0' : '') + date1.getDate();
							$('.addHotelbotton').attr('addBookingEndDate', self.addBookingEndDate);
							$('.addHotelbotton').attr('addBookingDate', self.addBookingDate);

							$('.addHotelbotton').attr('hotel_id', self.hotel_id);
							$('.addHotelbotton').attr('roomtype_id', self.roomtype_id);
							$('.addHotelbotton').attr('addBookingRoom_id', self.addBookingRoom_id); 
							
							let divs = '<span class="Dts1">' + self.addBookingDate + '</span> - <span class="Dts1">' + self.addBookingEndDate + '</span>';
							$('.booking_popDts').append(divs);
							$('.booking_main_div').removeClass('display_none');
							const left = $(".block_" + cod1 + cod2).offset().left;
							const top = $(".block_" + cod1 + cod2).offset().top;
							$(".booking_main_div").css({ "top": top + 20 + 'px', "left": left + 50 + 'px' });
						}
					});

					$('body').on("dragstart", "div[class*=event]", function (event) {
						if (self.modify_access == false) {
							return false;
						}
						self.calledChangeRoom = 'no';
						pickDate = $(this).attr('ClaeDate');
						arr_x = $(this).attr('x_coordinate');
						arr_y = $(this).attr('y_coordinate');
						self.start_room_id = $(this).attr('room_id');
						self.start_roomtype_id = $(this).attr('roomtype_id');
						$('.popover').hide();
						$('.popoverData').css({ "display": "none" });
						var dt = event.originalEvent.dataTransfer;
						dt?.setData("className", $(this).attr("class"));
						dt?.setData("booking_room_id", $(this).attr("booking_room_id"));
						setTimeout(() => {
							dt?.setData("colspan", $(this).closest('td').attr("colspan"));
						}, 1000);
					});

					$('body').on("dragenter dragover drop", "table#booking_table td", function (event) {
						event.preventDefault();
						if (event.currentTarget.classList[0] == 'dropNotallowed' || event.currentTarget.classList[4] == 'dropNotallowed' || event.currentTarget.classList[8] == 'dropNotallowed') {
							return false;
						}
						if (event.type === "drop") {
							this.pointernone = true;
							setTimeout(() => {
								const NewDaTeSFilter = $(".DaTeSFilter").val();
								$('.scrollrightBookingDate').val(NewDaTeSFilter);
							}, 1000);
							let ind_x = $(this).attr('ind_x');
							let ind_y = $(this).attr('ind_y');
							$(this).find('.task' + ind_x + ind_y).remove();
							var className = event.originalEvent.dataTransfer.getData("className", $(this).attr("class"));
							var booking_room_id = event.originalEvent.dataTransfer.getData("booking_room_id", $(this).attr("booking_room_id"));
							var colspan = event.originalEvent.dataTransfer.getData("colspan", $(this).attr("booking_room_id"));
							
							de = $("."+className).detach();


							de.appendTo($(this));
							$(this).attr('colspan', colspan);
							for (var i = 0; i < (colspan - 1); i++) {
								$(this).nextAll('td').eq(i).hide();
							}
							room_name = $(this).attr('room_name');
							room_id = $(this).attr('room_id');
							if (self.calledChangeRoom == 'no') {
								if (self.start_room_id != room_id) {
									self.calledChangeRoom = 'yes';
									$('.changeRoombotton').attr('booking_room_id', booking_room_id);
									$('.changeRoombotton').attr('room_id', room_id);
									$('.changeRoombotton').attr('className', className);
									$('.changeRoombotton').attr('room_name', room_name);
									$('.changeRoombotton').attr('arr_x', arr_x);
									$('.changeRoombotton').attr('arr_y', arr_y);
									$('.changeRoombotton').attr('start_roomtype_id', self.start_roomtype_id);
									$('.changeRoombotton').trigger('click');
								}
							}
						}
					});

					$(document).on("click", "table .emptyTd", function (event) { 
						// alert("A"); 
						event.preventDefault();
						room_id_b = $(this).attr('room_id');
						$(this).addClass('activeCell');
						$('.addHotelbotton').attr('hotel_id', self.hotel_id);
						$('.addHotelbotton').attr('roomtype_id', self.roomtype_id);
						$('.addHotelbotton').attr('addBookingRoom_id', self.addBookingRoom_id); 
						$('.addHotelbotton').attr('addBookingEndDate', self.addBookingEndDate);
						$('.addHotelbotton').attr('addBookingDate', self.addBookingDate); 
					});

				});
			}
		} else {
			$('#noaccessModal').modal('show');
		}
		this.commonService.getbookser$.subscribe(data => {
			var day = new Date(this.calDate[0].date);
			var nextDay = new Date(day);
			nextDay.setDate(day.getDate() - 1);
			$('.scrolledWhere').val('');
			this.scrolledWhere = '';
			this.scrollWheredCheck = true;
			//alert("3")
			this.getBookings(this.hotelID, this.roomID, nextDay.toISOString().substring(0, 10));
		});
	}
	hover_mouseout(i, j) {
		$('.onhover_j' + j).removeClass('hoverActive');
		$('.onhover_i' + i).removeClass('hoverActive');
	}
	sand_hover_mouseover(date, hotelllID) {
		this.hoverTo = setTimeout(() => {
			this.onHover = true;
			this.isLoadertrue = false;
			if (this.sandbboxDate != date) {
				this.get_sandboxData(date, hotelllID)
				this.sandbboxDate = date;
			}
			if (this.sandbboxDate == date) {
				this.isLoadertrue = true;
			}
		}, 1000);
	}
	leave() {
		clearTimeout(this.hoverTo);
		this.onHover = false;
		this.isLoadertrue = false;
	}

	hover_mouseover(i, j) {
		$('.onhover_j' + j).addClass('hoverActive');
		$('.onhover_i' + i).addClass('hoverActive');
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sb) => sb.unsubscribe());
	}

	onChangeHotel(hotelID: any): void {
		this.ischangedHotel = 'yes';
		this.calDate = [];
		this.bookingsRow = [];
		let CurrentDateBooking = this.dateFilter1;
		this.hotelID = hotelID;
		this.roomID = 0;
		localStorage.setItem('hotelID', this.hotelID);
		this.getRoomTypesList(this.hotelID);
		//alert("4")
		this.getBookings(this.hotelID, this.roomID, CurrentDateBooking);
	}
	manualChange() {
		this.auto_trigget_function();
	}
	onChangeRoom(roomID: any): void {
		this.ischangedHotel = 'yes';
		let CurrentDateBooking = this.dateFilter1;
		this.roomID = roomID;
		//alert("5")
		this.getBookings(this.hotelID, this.roomID, CurrentDateBooking);
	}
	create_month_year_arr() {
		this.monthyearArr = [];
		let date = new Date();
		var day: any = new Date(date.getTime());
		this.monthyearArr = [{
			months: date.getMonth() + 1,
			start_date: ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/01/' + date.getFullYear(),
			strmonth: this.monthNames[date.getMonth()] + ' ' + date.getFullYear(),
		}];
		let date1 = date;
		for (let i = 0; i <= 11; i++) {
			date.setMonth(date1.getMonth() - 1);
			var month: any = date.getMonth();
			var year: any = date.getFullYear();
			day = date.getDate();
			date1 = date;
			this.monthyearArr.push({
				months: date.getMonth() + 1,
				start_date: ((date.getMonth() + 1 < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/01/' + date.getFullYear(),
				strmonth: this.monthNames[month] + ' ' + year,
			});
		}
		this.selectedMonth = this.monthyearArr[0]['strmonth'];
	}

	next_month_data() {
		this.monthcounter--;
		$('.scrolledWhere').val('');
		var day = new Date(this.calDate[this.calDate.length - 1]['date']);
		var nextDay = new Date(day);
		nextDay.setDate(day.getDate() + 1);
		this.selectedMonth = this.monthNames[nextDay.getMonth()] + ' ' + this.calDate[this.calDate.length - 1]['date'].substring(0, 4);
		$('.scrollrightBookingDate').val(this.calDate[this.calDate.length - 1]['date']);
		$('.scrollleftBookingDate').val(this.calDate[this.calDate.length - 1]['date']);
		//alert("6")
		this.getBookings(this.hotelID, this.roomID, this.calDate[this.calDate.length - 1]['date']);
	}
	previous_month_data() {
		if (this.monthcounter < this.monthyearArr.length - 1) {
			this.monthcounter++;
			$('.scrolledWhere').val('');
			this.selectedMonth = this.monthyearArr[this.monthcounter]['strmonth'];
			let CurrentDateBooking = this.monthyearArr[this.monthcounter]['start_date'];
			$('.scrollrightBookingDate').val(CurrentDateBooking);
			$('.scrollleftBookingDate').val(CurrentDateBooking);
			//alert("7")
			this.getBookings(this.hotelID, this.roomID, CurrentDateBooking);
		}
	}
	prepareCalendarModels(bookings): void {
		let date = new Date(this.dateFilter);
		this.calendarDates = [];
		this.calendarDates.push({ date: new Date(date), bookingItem: null, isWeekEnd: true, Cid: null });
		for (let count = 0; count < 30; count++) {
			let calDate = new CalendarDatesModel();
			date.setDate(date.getDate() + 1); // add day
			calDate.date = new Date(date);
			this.calendarDates.push(calDate);
		}
		this.bookingsRow = [];
		let Dcount = -1;
		for (let booking of bookings) {
			let bookingRow = new BookingsRow();
			bookingRow.bookingDetail = booking;

			let increased = 'no';
			let count = 0;
			for (; count <= 30; count++) {
				let year = this.calendarDates[count].date.getFullYear();
				let month = ((this.calendarDates[count].date.getMonth() + 1) < 10) ? '0' + (this.calendarDates[count].date.getMonth() + 1) : (this.calendarDates[count].date.getMonth() + 1);
				let day = (this.calendarDates[count].date.getDate() < 10) ? '0' + this.calendarDates[count].date.getDate() : this.calendarDates[count].date.getDate();
				let dateString = year + '-' + month + '-' + day;
				let calendarDate = new CalendarDatesModel();
				calendarDate.date = this.calendarDates[count].date;
				if (booking.row_type == "rooms") {
					if (increased == 'no') {
						Dcount++;
						increased = 'yes';
					}
					for (let item of booking.bookings) {
						calendarDate.Cid = Dcount;
						if (item.booking != null) {
							if (dateString == item.booking.reservation_from) {
								calendarDate.bookingItem = item.booking
								count += item.booking.numberOfDays > 1 ? item.booking.numberOfDays - 1 : 0; // colspan       
							}
						}
					}
					bookingRow.calendarDates.push(calendarDate);
				}
			}
			this.bookingsRow.push(bookingRow);
		}
	}

	onChangeDateFilter(): void {
		this.calDate = [];
		this.bookingsRow = [];
		$('.scrollrightBookingDate').val(this.dateFilter);
		$('.scrollleftBookingDate').val(this.dateFilter);
		//alert("8")
		this.getBookings(this.hotelID, this.roomID, this.dateFilter);
	}
	Scrollforword_callApi(): void {
		// this.scrollCheck = false; 
		if ($('.scrolledWhere').val() == 'forword') {
			this.dateFilter = $('.DaTeSFilter').val();
			//alert("9")
			this.getBookings(this.hotelID, this.roomID, this.calDate[this.calDate.length - 1]['date']);
		}
	}
	Scrollbackwordword_callApi(): void {
		// this.scrollCheck = false; 
		if ($('.scrolledWhere').val() == 'backword') {
			let CurrentDateBooking = $('.DaTeSFilter').val();
			//alert("10")
			this.getBookings(this.hotelID, this.roomID, CurrentDateBooking);
		}
	}
	addRestrictions() {
		this.addBookingDate = $('.addHotelbotton').attr('addBookingDate');
		this.addBookingEndDate = $('.addHotelbotton').attr('addBookingEndDate');
		this.arrivel_date = this.addBookingDate;
		if (!this.addBookingEndDate) {
			this.departure_date = this.addBookingDate;
		} else {
			this.departure_date = this.addBookingEndDate;
		}
		this.restriction_action = '';
		this.restriction_date = '';
		this.restriction_release = null;
		$('.Restrictions_Days input').prop('checked', false);
		$('.cancellationPolicies input').prop('checked', false);
		$('.booking_main_div').addClass('display_none');
		$('#RestrictionsModal').modal("show");
	}
	temporary_closuer() {
		$('.booking_main_div').addClass('display_none');
		$('#Temporary_closuerModal').modal("show");
		this.temporary_closure = '';
	}
	selectRoomfromDropdown() {

	}

	assignRoom() {
		const arr = [];
		$('.sandBoxpopupallData .selectSandboxRoom').each(function () {
			var room_id = $(this).val();
			var booking_id = $(this).attr('booking_room_id');
			if (room_id) {
				arr.push({
					'room_id': room_id,
					'booking_id': booking_id,
				})
			}
		});
		if (arr.length > 0) {
			var comman_data = {
				myurl: `${environment.apiUrl}/hotels/sand-box-bookings/assign-room`,
				array: arr,
			};
			this.commonService.assignroom_service_funcation(comman_data).subscribe((response) => {
				$('#SandBoxModal').modal("hide");
				//alert("11")
				this.getBookings('hotelID', 'roomID', this.dateFilter1);
				this.assignRoomActive = true;
			}, err => {
				if (err['error']['errors']) {
					this.toastr.error(err['error']['errors']['rooms']);
				}else {
					this.toastr.error("Something Wrong!", "Please try again later");
				}
			}); 
		}
	}

	autoassignRoom() {
		var comman_data = {
			myurl: `${environment.apiUrl}/hotels/sand-box-bookings`,
			date: this.SandselectedDate,
			hotel_id: this.Sandselectedhotelid,
			auto_assign: 1,
		};
		this.commonService.comman_service_funcation(comman_data).subscribe((response) => {
			$('#SandBoxModal').modal("hide");
			//alert("12")
			this.getBookings('hotelID', 'roomID', this.dateFilter1);
			this.assignRoomActive = true;
		}, err => {
			this.get_sandboxData(comman_data.date, comman_data.hotel_id);
			//alert("13")
			this.getBookings('hotelID', 'roomID', this.dateFilter1);
			if (err['error']['errors']) {
				this.toastr.error(err['error']['errors']['rooms']);
			}else {
				this.toastr.error("Something Wrong!", "Please try again later");
			}
		});
	}
	save_temporary_closuer() {
		let to_date = '';
		this.addBookingEndDate = $('.addHotelbotton').attr('addBookingEndDate');
		this.addBookingDate = $('.addHotelbotton').attr('addBookingDate');
		if (!this.addBookingEndDate) {
			to_date = this.addBookingDate;
		} else {
			to_date = this.addBookingEndDate;
		}
		var comman_data = {
			myurl: `${environment.apiUrl}/hotels/temporary-closure`,
			reason: this.temporary_closure,
			room_id: this.addBookingRoom_id,
			from_date: this.addBookingDate,
			to_date: to_date,
		};
		this.commonService.comman_service_funcation(comman_data).pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
				$('#Temporary_closuerModal').modal("hide");
				//alert("14")
				this.getBookings('hotelID', 'roomID', this.dateFilter1);
				return err;
			})).subscribe((response) => {
			$('#Temporary_closuerModal').modal("hide");
			//alert("15")
			this.getBookings('hotelID', 'roomID', this.dateFilter1);
		});
	}
	trigget_function() {
		$(document).ready(() => {
			$('.hiddenbutton').trigger("click");
		});
	}
	auto_trigget_function() {
		$(document).ready(() => {
			$('.manualChangedhotel').trigger("click");
		});
	}
	getBookings(hotelID: any, roomID: any, dateFilter: any): void {
		if (this.scrollWheredCheck == true) {
			$('.scrolledWhere').val(''); 
			let date = new Date(this.dateFilter);
			var day: any = new Date(date.getTime());
			day.setDate(date.getDate() - 15);
			var month: any = day.getMonth() + 1;
			var year: any = day.getFullYear();
			day = day.getDate();
			var changedDate = month + '/' + day + '/' + year;
			$('.DaTeSFilter').val(changedDate);
		}
		this.pointernone = true;
		this.pointernone$.next(true);
		this.trigget_function();
		var date
		if (localStorage.getItem('bookingDate')) {
			date = localStorage.getItem('bookingDate');
		} else {
			date = this.formatUIDateStringToAPIDateString(dateFilter);
		}
		let newSelectedMonthYear = new Date(date);
		var newSelectedMonth = newSelectedMonthYear.toLocaleString('default', { month: 'long' });
		var newSelectedYear = newSelectedMonthYear.getFullYear();
		let newSelectedDate = newSelectedMonth + ' ' + newSelectedYear;
		this.selectedMonth = newSelectedDate;
		var newFiltermonth: any = newSelectedMonthYear.getMonth() + 1;
		var newFilteryear: any = newSelectedMonthYear.getFullYear();
		var newFilterday = newSelectedMonthYear.getDate();
		var newFilterdate = newFiltermonth + '/' + newFilterday + '/' + newFilteryear;
		this.dateFilter = newFilterdate;
		this.IsLoading = true;
		this.isLoadingNew$.next(true); 
		this.commonService.API_URL = `${environment.apiUrl}/hotels/booking-calendar`;
		if (localStorage.getItem("newBooking")) {
			$('.scrollrightBookingDate').val(date);
			$('.scrollleftBookingDate').val(date);
		}
		var comman_data = {
			myurl: `${environment.apiUrl}/hotels/booking-calendar`,
			room_id: roomID,
			start_date: date,
			room_type_id: this.roomID,
			hotel_id: this.hotelID,
			months: '1',
		};
		this.commonService.comman_service_funcation(comman_data).pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) { 
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
				this.pointernone = false;
				this.pointernone$.next(false);
			  	return err;
			})).subscribe((response) => {
			localStorage.removeItem("newBooking");
			this.pointernone = false;
			this.pointernone$.next(false);
			let mythis = this;
			mythis.availabD = response[0].total_availability;
			this.trigget_function();
			// //alert("mythis.scrolledWhere"); 
			//alert(this.scrollCheck)
			mythis.scrolledWhere = $('.scrolledWhere').val();
			if (this.assignRoomActive) {
				this.scrolledWhere = '';
			}
			localStorage.removeItem('bookingDate');
			if (mythis.scrolledWhere == 'forword') {
				mythis.forwordScrollfuntion(response);
			} else if (mythis.scrolledWhere == 'backword') { 
				if (this.calledChangeRoom == 'yes') {
					mythis.availabD = response[0].total_availability;
				} else {
					mythis.availabD.concat(response[0].total_availability);
				}
				mythis.backwordScrollfuntion(response);
			} else {
				if (response.length > 0) {
					mythis.calDate = response[0].total_availability;
				}
				this.assignRoomActive = false;
				mythis.bookingsRow = response;
				this.IsLoading = false;
				this.isLoadingNew$.next(false);
				if (mythis.isScrolled_once == "yes") {
					mythis.isScrolled_once = "yes";
				}
			}
			this.manualChange();
			if (this.urlHotel_id != 0 && this.hotelID != 0) {
				setTimeout(function () {
					var leftPos = $('.set_' + mythis.urlHotel_id + '_' + mythis.urldate + '_' + mythis.urlroom_id).position();
					mythis.router.navigate([`/bookings/calendar`]);
				}, 1000);
			}
			if (this.scrollWheredCheck == true) {
				this.scrollWheredCheck = false;
				$('.oneScrolled').val('0');
			}

		});
	} 

	forwordScrollfuntion(response) {
		// this.scrollCheck = false;
		let mythis = this;
		let keys = 0;
		let key_s = 0;
		if (response.length > 0) {
			$(response).each(function (keys, value) {
				if (value.row_type == "hotelname") {
					if (mythis.bookingsRow[keys]['total_availability'] == undefined) {
						$(value.total_availability).each(function (key, val) {
							value.total_availability.push(val);
						});
						mythis.bookingsRow.splice(keys, 0, value);
					} else {
						mythis.bookingsRow[keys]['total_availability'] = mythis.bookingsRow[keys]['total_availability'].concat(value.total_availability);
					}
				} else if (value.row_type == "roomtype") {
					if (mythis.bookingsRow[keys]['availability'] == undefined) {
						do {
							$(value.availability).each(function (key, val) {
								let val1: any = { date: "0000-00-00", booking: null };
								value.availability.push(val1);
							});
							mythis.bookingsRow.splice(keys + 1, 0, value);
						}
						while (mythis.bookingsRow[keys]['availability'] != undefined);
					} else {
						$(value.availability).each(function (keys1, value1) {
							mythis.bookingsRow[keys]['availability'].push(value1);
						});
					}
				} else if (value.row_type == "rooms") {
					if (mythis.bookingsRow[keys]['bookings'] == undefined) {
						if (value.room_name == "Sand Box") {
							$(mythis.calDate).each(function (key, val) {
								value.bookings.splice(key, 0, val);
							});
							mythis.bookingsRow.splice(keys, 0, value);
						}
					} else {
						$(value.bookings).each(function (keys3, value3) {
							mythis.bookingsRow[keys]['bookings'].push(value3);
						});
					}
				}
			});
			$(response[0].total_availability).each(function (key, val) {
				mythis.calDate.push(val);
			});
			this.IsLoading = false;
			this.isLoadingNew$.next(false);
			$('.oneScrolled').val('0');
		}
	}

	backwordScrollfuntion(response) {
		// this.scrollCheck = false; 
		let mythis = this;
		let keys = 0;
		let key_s = 0;
		if (response.length > 0) {
			let avel = response[0].total_availability;
			mythis.calDate = avel.concat(mythis.calDate);
			$(response).each(function (keys, value) {
				if (value.row_type == "hotelname") {
					mythis.bookingsRow[keys]['total_availability'] = value.total_availability.concat(mythis.bookingsRow[keys]['total_availability']);
				} else if (value.row_type == "roomtype") {
					mythis.bookingsRow[keys]['availability'] = value.availability.concat(mythis.bookingsRow[keys]['availability']);
				} else if (value.row_type == "rooms") {
					mythis.bookingsRow[keys]['bookings'] = value.bookings.concat(mythis.bookingsRow[keys]['bookings']);
				}
			});
			$(".previous_booking").addClass('display_none');
			this.IsLoading = false;
			this.isLoadingNew$.next(false);
			$('.oneScrolled').val('0');
		}
	}
	expand_hotel(hotel_id) {
		$('.hotel_plus' + hotel_id).addClass('display_none');
		$('.hotel_minus' + hotel_id).removeClass('display_none');
		$('.hotel_id' + hotel_id).removeClass('display_none');
	}
	collapse_hotel(hotel_id) {
		$('.hotel_id_' + hotel_id).addClass('display_none');
		$('.hotel_id' + hotel_id).addClass('display_none');
		$('.hotel_plus' + hotel_id).removeClass('display_none');
		$('.hotel_plus_' + hotel_id).removeClass('display_none');
		$('.hotel_minus' + hotel_id).addClass('display_none');
		$('.hotel_minus_' + hotel_id).addClass('display_none');
	}

	expand_roomtype(roomtype_id) {
		$('.roomtype_plus' + roomtype_id).addClass('display_none');
		$('.roomtype_minus' + roomtype_id).removeClass('display_none');
		$('.roomtype_id' + roomtype_id).removeClass('display_none');
	}
	collapse_roomtype(roomtype_id) {
		$('.roomtype_id' + roomtype_id).addClass('display_none');
		$('.roomtype_plus' + roomtype_id).removeClass('display_none');
		$('.roomtype_minus' + roomtype_id).addClass('display_none');
	}
	editBooking(date: any, data: any, booking: any, cod1: any, cod2: any, bookings_count: any, hotel_id: any): void {
		if (this.modify_access == true) {
			let room_id = data.room_id;
			let room_name = data.room_name;
			this.roomtype_id = data.roomtype_id;
			this.addBookingDate = date;
			this.hotel_id = hotel_id;
			let dates = new Date(date);
			this.addBookingRoom_id = room_id;
			if (booking != undefined) {
				window.open(`/bookings/edit/${booking?.id}`)
			} else {
				if (room_name == 'Sand Box') {
					$('.booking_main_div').addClass('display_none');
					$('.dragSelect').removeClass('activeCell').removeClass('radius_left').removeClass('radius_right');
					this.addBookingEndDate = '';
					if (bookings_count != 0) {
						$('#SandBoxModal').modal("show");
						this.get_sandboxData(date, data.hotel_id);
					}
				} else {
					this.hotel_id = data.hotel_id;
					$('.booking_popDts').empty();
					let divs = '<div class="Dts1">' + this.addBookingDate + '</div>';
					$('.booking_popDts').append(divs);
					$('.booking_main_div').removeClass('display_none');
					const left = $(".block_" + cod1 + cod2).offset().left;
					const top = $(".block_" + cod1 + cod2).offset().top;
					$(".booking_main_div").css({ "top": top + 'px', "left": left + 'px' });
				}
			}
		}
	}
	editSandBooking(data) {
		if (data != undefined) {
			window.open(`/bookings/edit/${data.booking_id}`)
		}
	}
	get_sandboxData(date, hotel_id) { 
		this.sanboxloader = true;
		this.SandselectedDate = date;
		this.Sandselectedhotelid = hotel_id;
		var comman_data = {
			myurl: `${environment.apiUrl}/hotels/sand-box-bookings`,
			date: date,
			hotel_id: hotel_id,
		};
		this.commonService.comman_service_funcation(comman_data).pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
				this.isLoadertrue = true;
				this.sanboxloader = false;
			  	return err;
			})).subscribe((response) => {
			this.sandBoxpopupData = response;
			this.isLoadertrue = true;
			this.sanboxloader = false;
			$('.selectSandboxRoom').val("");
			setTimeout(function () {
				$('.hiddenbutton').trigger("click");
			}, 200);
		});
	}
	save_restrictions() {
		var restrictions_days_arr = [];
		var policy_arr = [];
		$('.Restrictions_Days input:checked').each(function () {
			let days = $(this).attr('name');
			restrictions_days_arr.push(days);
		});
		$('.cancellationPolicies input:checked').each(function () {
			let policy = $(this).attr('name');
			policy_arr.push(policy);
		});
		$('.common_error').addClass('display_none');
		if (!this.arrivel_date) {
			$('.arrivel_date').removeClass('display_none');
			return false;
		} else if (!this.departure_date) {
			$('.departure_date').removeClass('display_none');
			return false;
		} else if (this.departure_date < this.arrivel_date) {
			$('.departure_date1').removeClass('display_none');
			return false;
		} else if (restrictions_days_arr.length == 0) {
			$('.restric_days').removeClass('display_none');
			return false;
		} else if (!this.restriction_action) {
			$('.restriction_action').removeClass('display_none');
			return false;
		} else if (!this.restriction_date) {
			$('.restriction_date').removeClass('display_none');
			return false;
		} else if (policy_arr.length == 0) {
			$('.c_policies').removeClass('display_none');
			return false;
		} else if (!this.restriction_release) {
			$('.restriction_release').removeClass('display_none');
			return false;
		} else {
			var comman_data = {
				myurl: `${environment.apiUrl}/hotels/booking-restrictions`,
				room_id: this.addBookingRoom_id,
				arrival: this.arrivel_date,
				departure: this.departure_date,
				specific_days: restrictions_days_arr,
				actions: this.restriction_action,
				days: this.restriction_date,
				cancellation_policies: policy_arr,
				release: this.restriction_release,
			};
			this.commonService.comman_service_funcation(comman_data).pipe(
				catchError((err) => {
					if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
					this.close_modal();
					return err;
				})).subscribe((response) => {
				this.close_modal();
				//alert("16")
				this.getBookings('hotelID', 'roomID', this.dateFilter1);
			});
		}
	}
	addBooking(): void {
		let dates = new Date(this.addBookingDate);

		// alert(dates); 


		// this.addBookingEndDate = $('.addHotelbotton').attr('addBookingEndDate');
		// this.addBookingDate = $('.addHotelbotton').attr('addBookingDate');
		// if (!this.addBookingEndDate) {
		// 	to_date = this.addBookingDate;
		// } else {
		// 	to_date = this.addBookingEndDate;
		// }

		
		this.hotel_id = $('.addHotelbotton').attr('hotel_id');
		this.roomtype_id = $('.addHotelbotton').attr('roomtype_id');
		this.addBookingDate = $('.addHotelbotton').attr('addBookingDate');
		this.addBookingRoom_id = $('.addHotelbotton').attr('addBookingRoom_id');
		this.addBookingEndDate = $('.addHotelbotton').attr('addBookingEndDate');
		if (this.addBookingEndDate) {
			this.router.navigate([`/bookings/add`], {
				queryParams: {
					'hotel_id': this.hotel_id,
					'roomtype_id': this.roomtype_id,
					'room_id': this.addBookingRoom_id,
					'date': this.addBookingDate,
					'end_date': this.addBookingEndDate,
				}
			});
		} else {
			this.router.navigate([`/bookings/add`], {
				queryParams: {
					'hotel_id': this.hotel_id,
					'roomtype_id': this.roomtype_id,
					'room_id': this.addBookingRoom_id,
					'date': this.addBookingDate,
				}
			});
		}
	}

	getHotelDetail() {
		this.commonService.API_URL = `${environment.apiUrl}/hotels/hotels`;
		this.commonService.getItemById(this.hotelID).subscribe(
			response => { });
	}

	close_modal() {
		$('.modal').modal("hide");
	}
	getHotelList() {
		this.pointernone$.next(true);
		this.commonService.API_URL = `${environment.apiUrl}/hotels/hotels`;
		this.commonService.getList().pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
				this.pointernone$.next(false);
				return err;
			})).subscribe(
			response => {
				this.hotelList = response.data;
				this.pointernone$.next(false);
				if (this.hotelList) {
					if (this.urlHotel_id && this.urlHotel_id != undefined) {
						this.hotelID = this.urlHotel_id;
					}
					this.getRoomTypesList(this.hotelID);
					//alert("17")
					this.getBookings(this.hotelID, this.roomID, this.dateFilter1);
				}
			}
		);
	}

	getRoomTypesList(hotelID) {
		this.commonService.API_URL = `${environment.apiUrl}/hotels/${hotelID}/room-types`;
		this.commonService.getList().pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
				return err;
			})).subscribe(
			response => {
				this.roomTypesList = response;
			}
		);
	}

	setDateFilter(): void {
		let date = new Date();
		var day: any = new Date(date.getTime());
		var date1: any = new Date(date.getTime());
		day.setDate(date.getDate());
		var month: any = day.getMonth() + 1;
		var year: any = day.getFullYear();
		day = day.getDate();
		date1.setDate(date.getDate() - 10);
		date.setDate(date.getDate() - 1) // subtract one day
		this.dateFilter1 = date1.getMonth() + 1 + '/' + date1.getDate() + '/' + date1.getFullYear();
		this.dateFilter = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
		this.Todaysdate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
		$(".CurrentDateBooking").val(this.dateFilter);
		$(".scrollleftBookingDate").val(this.dateFilter1);
		$(".scrollrightBookingDate").val(this.dateFilter1);
	}

	nextMonth(): void {
		let date = new Date(this.dateFilter);
		date.setMonth(date.getMonth() + 1);
		this.dateFilter = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
		//alert("18")
		this.getBookings(this.hotelID, this.roomID, this.dateFilter);
	}

	previousMonth(): void {
		let date = new Date(this.dateFilter);
		date.setMonth(date.getMonth() - 1);
		this.dateFilter = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
		//alert("19")
		this.getBookings(this.hotelID, this.roomID, this.dateFilter);
	}

	setStyles(): void {
		let elements = document.querySelector('body');
		this.renderer2.setStyle(elements, 'overflow', 'auto');
		let element = document.querySelector('.container');
		this.renderer2.setStyle(element, 'margin-left', '0px');
		this.renderer2.setStyle(element, 'padding', '25px 0px 0px 0px');
		this.renderer2.setStyle(element, 'padding-bottom', '0px');
		element = document.querySelector('.content');
		this.renderer2.setStyle(element, 'padding-bottom', '0px');
		element = document.querySelector('.aside-fixed.aside-minimize .wrapper');
		this.renderer2.setStyle(element, 'padding-bottom', '0px');
		element = document.querySelector('.aside-minimize .aside');
		this.renderer2.setStyle(element, 'z-index', '900');
		this.renderer2.setStyle(element, 'border-right', '1px solid #eee');
		element = document.querySelector('#wrapper');
		this.renderer2.setStyle(element, 'max-height', $('body').height() - 60 + 'px');
	}
	changeRoom(): void {
		if (this.calledChangeRoom == 'yes') {
			this.dateFilter = this.dateFilter1;
		} 
		this.IsLoading = true;
		this.isLoadingNew$.next(true);
		var booking_room_id = $('.changeRoombotton').attr('booking_room_id');
		var room_id = $('.changeRoombotton').attr('room_id');
		var className = $('.changeRoombotton').attr('className');
		var room_name = $('.changeRoombotton').attr('room_name');
		var arr_x = $('.changeRoombotton').attr('arr_x');
		var arr_y = $('.changeRoombotton').attr('arr_y');
		var roomtype_id = $('.changeRoombotton').attr('start_roomtype_id');
		var object: any = {};
		if (room_name == 'Sand Box') {
			object = {
				room_type_id: roomtype_id,
				myurl: `${environment.apiUrl}/hotels/change-room/${booking_room_id}`,
			}
		} else {
			object = {
				room_id: room_id,
				myurl: `${environment.apiUrl}/hotels/change-room/${booking_room_id}`,
			}
		}
		this.commonService.comman_service_funcation(object).subscribe(response => {
			let result = response;


			console.clear()
			console.log(response, "responseresponseresponse")


			// new code
			// let pickDateBook = "";

			// if(!response.booking) {
			// 	pickDateBook = response.rate_types[0].created_at;
			// }else {
			// 	pickDateBook = response.booking.checkout_time;	
			// }	

			// // this.dateFilter1 = 	pickDateBook.substring(0, 10); 
			// $('.CurrentDateBooking').val(pickDateBook.substring(0, 10));
			// $('.scrollrightBookingDate').val(pickDateBook.substring(0, 10));
			// $('.scrollleftBookingDate').val(pickDateBook.substring(0, 10)); 
			//  $(".DaTeSFilter").val(pickDateBook.substring(0, 10)); 
			//end new code 


			// let pickDateBook = "";

			// if(!response.booking) {
			// 	pickDateBook = this.dateFilter;
			// }else {
			// 	pickDateBook = response.booking.reservation_from;	
			// }	
			
			// alert(this.dateFilter)
			// this.dateFilter = pickDateBook; 

			// alert(this.dateFilter) 


			// var scrolledRight = $('.scrollrightBookingDate').val(this.dateFilter);
			// $('.scrollleftBookingDate').val(scrolledRight);   



			let getMoveStartDate = response.booking.reservation_from;

			// alert(getMoveStartDate);


			let newRoomChangeDate = new Date(getMoveStartDate);
			var RoomChangeDay = newRoomChangeDate.getDate() - 5;
			let RoomChangeMonth = newRoomChangeDate.getMonth() + 1;
			let RoomChangeYear = newRoomChangeDate.getFullYear();  

			let RoomChangePickDate = RoomChangeYear+'-'+RoomChangeMonth+'-'+RoomChangeDay;


			// alert(RoomChangePickDate);  

			$('.scrollrightBookingDate').val(RoomChangePickDate);
			$('.scrollleftBookingDate').val(RoomChangePickDate); 


			this.IsLoading = false; 
			this.isLoadingNew$.next(false); 
			if (result.message && result.message?.indexOf('Room changed successfully') != -1) {
				$('.scrolledWhere').val('');
				//alert("20") 
				// this.getBookings(this.hotelID, this.roomID, this.dateFilter);				
				this.getBookings(this.hotelID, this.roomID, RoomChangePickDate);				
			} else if (result.message && result.message?.indexOf('Room added to sandbox.') != -1) {
				$('.scrolledWhere').val('');
				//alert("21")
				// this.getBookings(this.hotelID, this.roomID, this.dateFilter);
				this.getBookings(this.hotelID, this.roomID, RoomChangePickDate);  			

			} else {
				let self = this;
				let bookingData = this.bookingsRow[arr_x]['bookings'][arr_y]['booking'];
				const modalRef = this.modalService.open(ChangeRoomModalComponent, {});
				modalRef.componentInstance.booking_room_id = booking_room_id;
				modalRef.componentInstance.room_id = room_id;
				modalRef.componentInstance.room_name = room_name;
				modalRef.componentInstance.bookingData = bookingData;
				modalRef.componentInstance.existing_price = result?.existing_price;
				modalRef.componentInstance.rate_types = result?.rate_types;
				modalRef.componentInstance.date_filter = this.dateFilter;
				modalRef.result.then((res) => {
					$('.close').trigger('click');
					self.trigget_function();
				});
			} 
		}, err => {
			this.toastr.error('Something went wrong.');
			this.IsLoading = false;
			this.isLoadingNew$.next(false);
			//alert("22")
			this.getBookings('hotelID', 'roomID', this.dateFilter1);
		});   /* */
	} 



	go_to_todays_calander() {
		var leftPos = $('.Todaysdate').position();
	}

	formatUIDateStringToAPIDateString(UIDateString): string {
		if (UIDateString.indexOf("-") > -1) {
			var day = new Date(UIDateString);
			var nextDay = new Date(day);
			nextDay.setDate(day.getDate() + 1);

			return nextDay.toISOString().substring(0, 10);
		} else {
			return UIDateString.split('/')[2] + '-' + UIDateString.split('/')[0] + '-' + UIDateString.split('/')[1];
		}
	}

	formatAPIDateStringToUIDateString(APIDateString): string {
		return APIDateString.split('-')[1] + '/' + APIDateString.split('-')[2] + '/' + APIDateString.split('-')[0];
	}

	hiddenbutton() { console.log("do nothing"); }

	tableExpendFun() {
		this.tableExpend = true;
		this.getBookings;
	}

	tableCollapseFun() {
		this.tableExpend = false;
		this.getBookings;
	}


}