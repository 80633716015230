<!-- start: Paginator -->
<div class="d-flex align-items-center py-3">
  <!-- begin: loading -->
  <div class="d-flex align-items-center" *ngIf="isLoading">
    <div class="mr-2 text-muted">Loading...</div>
    <div class="spinner spinner-primary mr-10"></div>
  </div>
  <!-- end: loading -->

  <!-- begin: pageSize -->
  <!-- <span class="react-bootstrap-table-pagin ation-total">
    &nbsp;Total Records: 
    {{ paginator.total }}
    </span
  > -->

  <!-- end: pageSize -->
</div>
<!-- {{paginator | json}} --> 
<div class="btn-group" role="group" aria-label="Basic example" *ngIf="paginator.lastNoPage">
  
  <button class="btn btn-primary  mr-3" (click)="prevpage()" [disabled]="paginator.page == 1">Prev</button> 
  <button class="btn btn-primary" (click)="nextpage()" [disabled]="paginator.page == paginator.lastNoPage">Next</button>   

  <!-- [disabled]="paginator.page == paginator.lastNoPage" -->
</div>

<!-- end: Paginator -->
