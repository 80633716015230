
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';
import {WebcamModule} from 'ngx-webcam';
import { ToastrModule } from 'ngx-toastr';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgCircleProgressModule } from 'ng-circle-progress'; 
import { FormsModule } from '@angular/forms';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_PMSChicstays/partials/layout/splash-screen/splash-screen.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatTableModule } from '@angular/material/table';
import { TaskComponent } from './modules/maintenance/task/task.component';
import { CategoriesComponent } from './modules/maintenance/categories/categories.component';
// import { MaintenanceComponent } from './modules/maintenance/maintenance.component';


// import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 



function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}
 
@NgModule({ 
  declarations: [AppComponent, TaskComponent, CategoriesComponent], 
  imports: [
    MatTableModule,
    FormsModule,
    BrowserModule,
	  CarouselModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,    
    InlineSVGModule.forRoot(),
  	ToastrModule.forRoot(),WebcamModule,
    NgbModule,
    // GooglePlaceModule,  
    NgMultiSelectDropDownModule.forRoot(),
    NgCircleProgressModule.forRoot({
			radius: 20,
			outerStrokeWidth: 5,
			innerStrokeWidth: 5,
			outerStrokeColor: '#FF6347',
			innerStrokeColor: '#bac1cf',
			animationDuration: 100,
      space: -5,
      titleFontSize: '10',
      showSubtitle: true,
      subtitle: '*',
      animation:true,
      startFromZero: true, 
      showInnerStroke: true,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    NgbActiveModal,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
