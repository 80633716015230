import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NG_ASYNC_VALIDATORS } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { catchError, delay, finalize, tap } from 'rxjs/operators';


@Component({
  selector: 'app-alert',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit, OnDestroy {
  @Input() errors: any;
  isLoading = false;
  errorMessage: any;
  subscriptions: Subscription[] = [];

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
     let msg = ``;
     //debugger
        let errorsList = Object.entries(this.errors?.errors);
        errorsList.forEach(function (error) {
          msg += error[1] + '<br />';
        });

        this.errorMessage = msg;
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}
