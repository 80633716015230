<div class="modal-content" >
  <div class="modal-header">
    <div class="modal-title h4" id="example-modal-sizes-title-lg">
      <ng-container>
        <ng-container>Different room type</ng-container>
      </ng-container>
    </div>
	<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closepopup()">
		<span style=" display: block !important;" aria-hidden="true">&times;</span>
	</button>
  </div>
  <div class="overlay overlay-block cursor-default modal-body">
    <ng-container *ngIf="isLoading$ | async">
      <div class="overlay-layer bg-transparent">
        <div class="spinner spinner-lg spinner-success"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="changeRoom">
      <form action="#" class="form form-label-right">
        <div class="form-group row">
			<div class="col-lg-12">
				<label>Room Type</label>
				<select class="form-control form-control-lg form-control-solid" (change)="change_roomType()" [(ngModel)] = "changeRoom.rate_type_id" name="room_type_id">
					<option *ngFor = "let item of rate_types; let i = index;" value="{{item.id}}" [attr.selected]="i==0"> {{item.detail.name}} ( {{item.price}} € )</option>
				</select>
			</div>
		</div>
		<!--<div class="form-group row">
			<div class="col-lg-6">
				<label>Current Price</label>
				<div class="form-control form-control-lg form-control-solid">{{existing_price}} €</div>
			</div>
			<div class="col-lg-6">
				<label>New Price</label>
				<div class="form-control form-control-lg form-control-solid">{{newprice}} <span *ngIf="newprice"> € </span></div>
			</div>
        </div>-->
			<div>
				<div class="reservation-overview horizontal">
					<div class="bookings clearfix">
						<!-- {{bookingData | json}} -->
						<div class="state-halfhour booked unpaid"
							[ngClass]="{'booked-blue': bookingData.status == 'confirmed', 'booked-green': bookingData.status == 'check-in' , 'booked-grey': bookingData.status == 'check-out' }"
							style="z-index: 1;" [ngbPopover]="BookingPopover"  triggers="hover" placement="auto">
							<div class="booked-TInfo">
								<div style="padding: 12px 10px 10px 0px;"><i class="fas fa-caret-left"></i></div>
								<div><span>{{bookingData.guest}}</span><br>
								<i class="fas fa-male"></i><span>{{bookingData.adult_count}}</span><i class="fas fa-child"></i><span>{{bookingData.children_count}}</span><span class="booked-price">{{bookingData.total_price?.calendar_price?.total}} €</span> </div>
							</div>
							<div 
								class="unpaidInner"
								[ngClass]="{'not-paid': bookingData.payment_atatus == 'not-paid', 'partially-paid': bookingData.payment_atatus == 'partially-paid', 'payed': bookingData.payment_atatus == 'payed' }"
							></div>
							<div class="groupreservationInner" *ngIf="bookingData.roomCount > 1"></div>
						</div>
					</div>
				</div>
				<!-- <div>{{calDate.date | date: 'dd'}}</div> -->
				<ng-template #BookingPopover>
					<!-- {{calDate.booking | json}} -->
					<span class="tool-tip-heading" *ngIf="bookingData.booker"><i class="fas fa-user"></i> &nbsp;{{bookingData.booker}}</span> <br />
					<i class="fas fa-person-booth"></i> &nbsp; <span class="roomName{{bookingData.booking_room_id}}">{{room_name}}</span><br />                        
					<i class="fas fa-clock"></i> &nbsp; <span>{{bookingData?.reservation_from | date: 'dd MMM'}} - {{bookingData?.reservation_to | date: 'dd MMM'}}</span> <br />                       
					<hr />
					<div *ngIf="bookingData.addons.length > 0">Accesories:</div> 
					<ul style="padding-left:17px" *ngIf="bookingData.addons">
						<li *ngFor = "let addon of bookingData.addons">{{addon}}</li>
					</ul>
					<hr *ngIf="bookingData.addons.length > 0" />
					<div *ngIf="bookingData.rooms.length > 0">Other rooms in this reservation:</div>
					<ul style="padding-left:17px" *ngIf="bookingData.rooms">
						<li *ngFor = "let room of bookingData.rooms">{{room}}</li>
					</ul>
					<hr *ngIf="bookingData.rooms.length > 0" />
					<div *ngIf="bookingData.total_price">Pricing:</div>
					<ul style="padding-left:17px" *ngIf="bookingData.total_price">
						<li>Price: <b>€ {{bookingData?.calendar_price?.price}}</b> </li>
						<li>Tax: <b>€ {{bookingData?.calendar_price?.tax_without_vat }}</b></li>
						<li>Accesories: <b>€ {{bookingData?.calendar_price?.extras_price_without_vat }}</b></li>
						<li>VAT: <b>€ {{bookingData?.calendar_price?.total_vat }}</b></li>
						<li>Pending: <b>€ {{bookingData?.calendar_price?.pending}}</b></li>
						<li>Total: <b>€ {{bookingData?.calendar_price?.total}}</b></li>
					</ul> 
				</ng-template>
			</div>
      </form>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary btn-elevate" (click)="changePrice()">Change price</button>
    <button type="button" class="btn btn-primary btn-elevate" (click)="keepSamePrice()">Keep same price</button>
    <!--<button type="button" class="btn btn-primary btn-elevate" (click)="closepopup()">Cancel</button>-->
  </div>
</div>