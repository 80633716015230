import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageSizes, PaginatorState } from '../../models/paginator.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Input() paginator: PaginatorState;
  @Input() isLoading;
  @Input() lastNoPage: any;

  @Output() paginate: EventEmitter<PaginatorState> = new EventEmitter();
  message: any; 
  @Output() messageEvent = new EventEmitter<string>();

  pageSizes: number[] = PageSizes;
  constructor() {}

  ngOnInit(): void {
  }


  pageChange(num: number) {
    this.paginator.page = num;
    this.paginate.emit(this.paginator);
  }

  nextpage() {
    var nextpage = this.paginator.page;
    this.paginator.page = nextpage + 1;
    this.paginator.lastNoPage = this.lastNoPage; 
    // this.paginator.page = ;
    this.paginate.emit(this.paginator);

  }

  prevpage() {
    var prevpage = this.paginator.page;
    this.paginator.page = prevpage - 1; 
    this.paginator.lastNoPage = this.lastNoPage;  
    
    // this.paginator.page = ;
    this.paginate.emit(this.paginator); 
  }


  sizeChange() {
    this.paginator.pageSize = +this.paginator.pageSize;
    this.paginator.page = 1;
    this.paginate.emit(this.paginator);
  }
}
