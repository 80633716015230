<div class="bookingCalendar">
<div id="spin-loader">
	<ng-container *ngIf="isLoading$ | async">
		<div class="overlay-layer bg-transparent">
			<div class="spinner spinner-lg spinner-success"></div>
		</div>
	</ng-container>
	<ng-container *ngIf="isLoadingNew$ | async"> 
		<div class="overlay-layer bg-transparent">
			<div class="spinner spinner-lg spinner-success"></div>
		</div>
	</ng-container> 
</div>

<button class="btn btn-lg manualChangedhotel" onclick="manualChangedhotel()" style="display:none">test</button>
<div *ngIf="read_access"  id="wrapper" onscroll="myFunction()" [ngClass]="{pointernone: pointernone$['_value'] == true}"> 
    <!-- 2nd -->
	<button class="Scrollforword_callApi" style="display:none" (click)="Scrollforword_callApi()"></button>
	<button class="Scrollbackwordword_callApi" style="display:none" (click)="Scrollbackwordword_callApi()"></button>
	<input class="scrolledWhere" type="hidden" value="">
	<input class="oneScrolled" type="hidden" value="0">
	<input class="CurrentDateBooking" type="hidden" value="">
	<input class="scrollleftBookingDate" type="hidden" value="">
	<input class="scrollrightBookingDate" type="hidden" value="">
	<div class="cal-overflow">
	<div id="calender-options-wrapper" style="z-index: 1000;">
        <div id="calender-options">
            <ul class="list-reset clearfix d-mid-flex">
                
                <li>
                    <div id="hotellist" (click)="manualChange()"> 					
                        <div class="dropdown show">
                            <select class="form-control filter-reservations select select-css "  [(ngModel)]="hotelID" #hotelDropDown
                                (change)="onChangeHotel(hotelDropDown.value)" name="hotel_list">
								<option value="0">All Hotels</option>
								<option *ngFor="let item of hotelList;" value="{{item.id}}"> {{item.property}} </option>
                            </select>
                        </div>
                    </div>
                </li>
                <li>
                    <div id="reservations">
                        <div class="dropdown show">
                            <select style="max-width: 200px;" class="form-control filter-reservations select select-css "  [(ngModel)]="roomID" #roomDropDown name="room-types"
                                (change)="onChangeRoom(roomDropDown.value)">
                                <option value="0">All room types</option>
                                <option *ngFor="let item of roomTypesList;" value="{{item.id}}">
                                    {{item.room_type_detail.name}} </option>
                            </select>
                        </div>
                    </div>
                </li>
				<li (click)="go_to_todays_calander()" class="today_Btn">Today</li>
				<li class="select_month"><i class="fa fa-angle-left" (click)="previous_month_data()"></i>{{selectedMonth}}<i class="fa fa-angle-right" (click)="next_month_data()"></i></li>
				<li style="padding-top: 5px;">Search Date:</li>
                <li style="padding-top: 4px;">
                    <div id="calender">
                        <form class="form-inline">
                            <div class="form-group">
                                <div class="input-group">
                                    <input id="date-picker-cntrl" class="form-control DaTeSFilter" [(ngModel)]="dateFilter"
                                        (ngModelChange)="manualChange();onChangeDateFilter()" placeholder="mm/dd/yyyy" name="dp"
                                        ngbDatepicker #d="ngbDatepicker">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                            type="button"> <i class="fas fa-calendar-alt"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form> 
                    </div>
                </li>
                <li style="display: none;">
                    <div id="slider-control">
                        <div class="slidecontainer">
                            <input type="range" min="1" max="100" value="50" class="slider" id="myRange">
                            <p><span id="demo"></span></p>
                        </div>
                    </div>
                </li>
                <li>
                    <!-- <div id="spin-loader">
                        <ng-container *ngIf="isLoading$ | async">
                            <div class="overlay-layer bg-transparent">
                                <div class="spinner spinner-lg spinner-success"></div>
                            </div>
                        </ng-container>
						<ng-container *ngIf="IsLoading">
                            <div class="overlay-layer bg-transparent">
                                <div class="spinner spinner-lg spinner-success"></div>
                            </div>
                        </ng-container>
                    </div> -->
                </li>
				<li class="tableCollapseBtn alignRightBtn" *ngIf="tableExpend == false">
                    <button class="btn btn-primary rounded" id="tableExpend" (click)="tableExpendFun()">Expend View</button> 
                </li>
				<li class="tableExpendBtn alignRightBtn" *ngIf="tableExpend == true">
                    <button class="btn btn-primary rounded" id="tableCollapse" (click)="tableCollapseFun()" >Normal View</button> 
                </li>
				<li class="add_booking_Btn alignRightBtn" *ngIf="modify_access"> 
                    <button class="rounded" routerLink="/bookings/add">Add Booking</button>
                </li>				
            </ul>
        </div>
		
        <!-- <div id="Search-box">
            <input class="form-control" type="text" placeholder="Search">
        </div> -->
    </div> 
	<table style="border: 1px solid;" id="booking_table" [ngClass]="{'disable_pointer': modify_access == false, 'tableExpend': tableExpend==true}">
        <thead>
            <tr >
                <th id="date-change-links">
                    <!-- <span style="width: 40%; float: left; font-size: 20px; padding-left: 10px;">
                        <a  (click) = "previousMonth()" class="fa fa-angle-double-left" style="color: #333;"></a>
                    </span>
                    <span style="width: 40%; float: right; text-align: right; font-size: 20px; padding-right: 10px; ">
                        <a  (click) = "nextMonth()" class="fa fa-angle-double-right" style="color: #333;"></a>
                    </span> -->
					<span>HOTELS</span>
                </th>

                <th *ngFor = "let calDate of calDate; let ind = index" class="CaLdate" [ngClass]="{'Todaysdate': calDate.date == Todaysdate}">
                    <span class="lbl-day">{{calDate.date | date: 'EEE'}}</span>
                    <span class="lbl-date">{{calDate.date | date: 'dd'}}</span>
                    <span class="lbl-month">{{calDate.date | date: 'MMM'}}</span>
				</th>
            </tr>
        </thead>
		<!-- {{tableCollapse}} -->
        <tbody>
			<tr *ngFor="let bookingRow of bookingsRow; let i = index" class="onhover_i{{i}} common_row_hover">
				<th *ngIf="bookingRow.row_type == 'hotelname'" class="dropNotallowed room-name" title="{{bookingRow.hotelname}}" [attr.hotel_id] = "bookingRow.hotel_id">
					<i class="far fa-plus-square display_none hotel_plus{{bookingRow.hotel_id}}" (click)="expand_hotel(bookingRow.hotel_id)"></i>
					<i class="far fa-minus-square hotel_minus{{bookingRow.hotel_id}}" (click)="collapse_hotel(bookingRow.hotel_id)"></i>
					&nbsp;<span class="room-number hotelname-number">{{bookingRow.hotelname}}</span>
				</th>
				<th *ngIf="bookingRow.row_type == 'roomtype'" class="dropNotallowed room-name hotel_id{{bookingRow.hotel_id}}" title="{{bookingRow.roomtype_name}}" [attr.roomtype_id] = "bookingRow.roomtype_id">
					<i class="far fa-plus-square display_none roomtype_plus{{bookingRow.roomtype_id}} hotel_plus_{{bookingRow.hotel_id}}" (click)="expand_roomtype(bookingRow.roomtype_id)"></i>
					<i class="far fa-minus-square roomtype_minus{{bookingRow.roomtype_id}} hotel_minus_{{bookingRow.hotel_id}}" (click)="collapse_roomtype(bookingRow.roomtype_id)"></i>
					&nbsp;<span class="room-number">{{bookingRow.roomtype_name}}</span>
				</th>
				<ng-container *ngIf="bookingRow.row_type == 'rooms'">
					<th *ngIf="bookingRow.room_name != 'Sand Box'" style="height: 50px;" class="room-name roomtype_id{{bookingRow.roomtype_id}} hotel_id_{{bookingRow.hotel_id}}" title="{{bookingRow.room_name}} {{bookingRow.room_number}}" [attr.room_id] = "bookingRow.room_id" [attr.roomtype_id] = "bookingRow.roomtype_id" [attr.hotel_id] = "bookingRow.hotel_id">
						<span class="room-number">{{bookingRow.room_name}}</span>
						<span class="room-name-right"> {{bookingRow.room_number}}</span>
					</th>
					<th *ngIf="bookingRow.room_name == 'Sand Box' && bookingRow.merge != 0" style="height: 50px;" [attr.rowspan]="bookingRow.merge" class="room-name hotel_id{{bookingRow.hotel_id}}" title="{{bookingRow.room_name}} {{bookingRow.room_number}}" [attr.room_id] = "bookingRow.room_id" [attr.roomtype_id] = "bookingRow.roomtype_id" [attr.hotel_id] = "bookingRow.hotel_id">
						<span class="room-number">{{bookingRow.room_name}}</span>
						<span class="room-name-right"> {{bookingRow.room_number}}</span>
					</th>
				</ng-container>
				<ng-container *ngIf="bookingRow.row_type == 'hotelname'">
					<td *ngFor="let availables of bookingRow.total_availability; let a = index" class="dropNotallowed" [attr.hotel_id] = "bookingRow.hotel_id">
						<div class="booked-green booked-availability">
							{{availables.available}}
							<div><span class="booked_s1">&nbsp;</span><span class="booked_s2">&nbsp;</span><span class="booked_s3">&nbsp;</span></div>	
						</div>
					</td>
				</ng-container>
				
				<ng-container *ngIf="bookingRow.row_type == 'roomtype'" >
					<td *ngFor="let availables of bookingRow.availability; let a = index" class="dropNotallowed hotel_id{{bookingRow.hotel_id}}" [attr.roomtype_id] = "bookingRow.roomtype_id">
						<div class="booked-green1 booked-availability">
							{{availables.available}}
							<div><span class="booked_s1">&nbsp;</span><span class="booked_s2">&nbsp;</span><span class="booked_s3">&nbsp;</span></div>	
						</div>
					</td>
				</ng-container>
				
				<ng-container *ngIf="bookingRow.row_type == 'rooms'" >
					<td *ngFor="let calDate of bookingRow.bookings; let j = index;"  [ngClass]="{'dropNotallowed': calDate?.booking,'emptyTd': !calDate?.booking,'previous_booking': calDate?.previous_booking==true,
						'sandboxC': bookingRow.room_name == 'Sand Box','Todaysdate': calDate.date == Todaysdate}"
						(mouseover)="hover_mouseover(i,calDate.date)" (mouseout)="hover_mouseout(i,calDate.date)"
						class="roomtype_id{{bookingRow.roomtype_id}} block_{{i}}{{j}} set_{{bookingRow?.hotel_id}}_{{calDate.date}}_{{bookingRow.room_id}} onhover_j{{calDate.date}} {{bookingRow.room_name === 'Sand Box' ? 'hotel_id' + bookingRow.hotel_id : 'hotel_id_' + bookingRow.hotel_id}} nodisp_none dragSelect newdargable" 
						(click)="editBooking(calDate.date,bookingRow,calDate.booking,i,j,calDate.bookings_count,bookingRow?.hotel_id)" [attr.room_id] = "bookingRow.room_id" [attr.ClaeDate] = "calDate.date" 
						[attr.roomtype_id] = "bookingRow?.roomtype_id" [attr.room_name] = "bookingRow?.room_name" [attr.ind_x] = "i" [attr.ind_y] = "j" [attr.hotel_id] = "bookingRow?.hotel_id" [attr.colspan]="calDate?.booking?.numberOfDays">
						
						<div  *ngIf="bookingRow.room_name == 'Sand Box'">
							<div *ngIf="calDate.bookings_count != '0'" (mouseenter)="sand_hover_mouseover(calDate.date, bookingRow?.hotel_id)" (mouseleave)="leave()" [ngbPopover]="sandboxHoverPopup" triggers="hover" placement="auto">
								<span>{{calDate.bookings_count}}</span>
							</div>
						</div>
						
						<div *ngIf="!calDate?.booking && bookingRow.room_name != 'Sand Box'" class="task{{i}}{{j}} dragSelect SelectDragable" style="height: 48px;" 
							 [attr.ClaeDate] = "calDate.date" [attr.room_id] = "bookingRow.room_id" draggable="true">
						</div>		
						<div *ngIf="calDate?.booking" [attr.resto]="calDate?.booking.reservation_to" [attr.resfrom]="calDate?.booking.reservation_from">	
							<div *ngIf="bookingRow.room_name != 'Sand Box'" class="event{{i}}{{j}} bookingDragdrop ui-widget-content" [attr.booking_room_id] = 'calDate.booking.booking_room_id' 
							[attr.ClaeDate] = "calDate.date" [attr.room_name] = "bookingRow?.room_name" [attr.roomtype_id] = "bookingRow?.roomtype_id" [attr.room_id] = "bookingRow.room_id" [attr.x_coordinate] = "i" [attr.y_coordinate] = "j" draggable="true">
								
								<div class="reservation-overview horizontal">
									<div class="bookings clearfix" [ngbPopover]="bookingPopup" triggers="hover" placement="auto" *ngIf = "calDate.booking">
										<div class="state-halfhour booked unpaid"
											[ngClass]="{'booked booked-single': calDate.booking != null, 'booked-blue': calDate.booking.status == 'confirmed', 'booked-green': calDate.booking.status == 'check-in' , 'booked-grey': calDate.booking.status == 'check-out', 'cancelled': calDate.booking.status == 'cancelled' }"
											style="z-index: 1;">
											<div class="booked-TInfo" *ngIf="tableExpend != true">
												<div style="padding: 12px 2px 10px 0px;"><i class="fas fa-caret-left"></i></div>
												<div><span>&nbsp; #{{calDate?.booking?.id}} / {{calDate.booking.guest}}</span><br>
												<i class="fas fa-male"></i><span>{{calDate.booking.adult_count}}</span><i class="fas fa-child"></i><span>{{calDate.booking.children_count}}</span><span class="booked-price">{{calDate.booking.total_price?.calendar_price?.total}} €</span> </div>
											</div>
											<div class="booked-TInfo" *ngIf="tableExpend == true">
												<div><span>&nbsp; #{{calDate?.booking?.id}} / {{calDate.booking.guest}}</span></div>
											</div>
											<div 
												class="unpaidInner"
												[ngClass]="{'not-paid': calDate.booking.payment_atatus == 'not-paid', 'partially-paid': calDate.booking.payment_atatus == 'partially-paid', 'payed': calDate.booking.payment_atatus == 'payed' }"
											></div>
											<div class="groupreservationInner" *ngIf="calDate.booking.roomCount > 1"></div>
										</div>
									</div>
								</div>
								<!--<div>{{calDate.date | date: 'dd'}}</div> -->
							</div>	
							<ng-template #bookingPopup>
								
								<!-- {{calDate.booking | json}} -->
								<span class="tool-tip-heading" *ngIf="calDate.booking.booker"><i class="fas fa-user"></i> &nbsp;{{calDate.booking.booker}}</span> <br />
								<i class="fas fa-person-booth"></i> &nbsp; <span class="roomName{{calDate.booking.booking_room_id}}">{{bookingRow?.room_name}}</span><br />                        
								<i class="fas fa-clock"></i> &nbsp; <span>{{calDate.booking?.reservation_from | date: 'dd MMM'}} - {{calDate.booking?.reservation_to | date: 'dd MMM'}}</span> <br />                       
								<hr />
								<div *ngIf="calDate.booking.addons.length > 0">Accesories:</div>
								<ul style="padding-left:17px" *ngIf="calDate.booking.addons">
									<li *ngFor = "let addon of calDate.booking.addons">{{addon}}</li>
								</ul>
								<hr *ngIf="calDate.booking.addons.length > 0" />
								<div *ngIf="calDate.booking.rooms.length > 0">Other rooms in this reservation:</div>
								<ul style="padding-left:17px" *ngIf="calDate.booking.rooms">
									<li *ngFor = "let room of calDate?.booking.rooms">{{room}}</li>
								</ul>
								<hr *ngIf="calDate.booking.rooms.length > 0" />
								<div *ngIf="calDate.booking.total_price">Pricing:</div>
								<ul style="padding-left:17px" *ngIf="calDate.booking.total_price">
									<li>Price: <b>€ {{calDate.booking?.calendar_price?.price}}</b> </li>
									<li>Tax: <b>€ {{calDate.booking?.calendar_price?.tax_without_vat }}</b></li>
									<li>Accesories: <b>€ {{calDate.booking?.calendar_price?.extras_price_without_vat }}</b></li>
									<li>VAT: <b>€ {{calDate.booking?.calendar_price?.total_vat }}</b></li>
									<li>Pending: <b>€ {{calDate.booking?.calendar_price?.pending}}</b></li>
									<li>Total: <b>€ {{calDate.booking?.calendar_price?.total}}</b></li>
								</ul>
							</ng-template>

						

						</div>
						<!-- {{itemData | json}} -->
						<ng-template #sandboxHoverPopup style="min-width: 100px;"> 
							<div class="d-flex">
							<ng-container *ngIf="sanboxloader">
								<div class="overlay-layer bg-transparent">
									<div class="spinner spinner-lg spinner-success" style="width:20px; height:20px;"></div>
								</div>
							</ng-container>
						</div>
							<div class="d-flex" *ngIf="isLoadertrue && !sanboxloader">
								<div *ngFor="let sanditem of sandBoxpopupData" style="padding:0 10px;">
									<span class="tool-tip-heading"><i class="fas fa-user"></i> &nbsp;{{sanditem.booking_guest}}</span> <br />
									<i class="fas fa-person-booth"></i> &nbsp; <span class="roomName{{sanditem.room_options.id}}">{{sanditem?.room_type_name}}</span><br />                        
									<i class="fas fa-clock"></i> &nbsp; <span>{{sanditem.reservation_from | date: 'dd MMM'}} - {{sanditem.reservation_to | date: 'dd MMM'}}</span> <br />                       
									<hr *ngIf="sanditem.rooms" />
									<ul style="padding-left:17px" *ngIf="sanditem.rooms">
										<li *ngFor = "let room of sanditem.rooms">{{room}}</li> 
									</ul>
									<hr *ngIf="sanditem.accessories" />
									<div *ngIf="sanditem.accessories">Accesories:</div>
									<ul style="padding-left:17px" *ngIf="sanditem.accessories">
										<li *ngFor = "let addon of sanditem.accessories">{{addon}}</li>
									</ul>
									<hr *ngIf="sanditem?.price" />

									<div *ngIf="sanditem?.price">Pricing:</div>
									<ul style="padding-left:17px" *ngIf="sanditem.price">
										<li>Price: <b>€ {{sanditem?.price}}</b> </li>
										<li>Tax: <b>€ {{sanditem?.tax}}</b></li>
										<li>Accesories: <b>€ {{sanditem?.extra_price }}</b></li>
										<li>VAT: <b>€ {{sanditem?.vat }}</b></li>
										<li>Pending: <b>€ {{sanditem?.pending}}</b></li>
										<li>Total: <b>€ {{sanditem?.pending}}</b></li> 
									</ul>
								</div>
							</div>
							<hr/>
						</ng-template>  
					</td>
				</ng-container>
			</tr>
		</tbody>
    </table>
	</div>
	<div class="display_none booking_main_div"> 
		<div class="booking_inner_div">
			<div class="booking_popDts"></div>
			<div class="common_booking_pop" (click)="addBooking()"><i class="fa fa-plus"></i>&nbsp; Add booking</div>
			<div class="common_booking_pop" (click)="temporary_closuer()"><i class="far fa-clone"></i>&nbsp; Temporary Closuer</div>
			<div class="common_booking_pop" (click)="addRestrictions()"><i class="fas fa-crosshairs"></i>&nbsp; Restrictions</div>
		</div>
	</div>
	
	<div id="SandBoxModal" class="modal" role="dialog"> 
		<div class="modal-dialog" style="max-width: 750px;">
			<!-- Modal content-->
			<div class="modal-content">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close_modal()">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body">
					<ng-container *ngIf="sanboxloader">
						<div class="overlay-layer bg-transparent">
							<div class="spinner spinner-lg spinner-success" style="width:20px; height:20px;"></div>
						</div>
					</ng-container>

					<div class="sandBoxpopupallData" *ngIf="!sanboxloader">
						<!-- {{sandBoxpopupData | json}} -->
						<!-- (click)="editBooking(calDate.date,bookingRow,calDate.booking,i,j,calDate.bookings_count,bookingRow?.hotel_id)" -->
						<div *ngFor="let item of sandBoxpopupData" class="row">
							<div class="col-md-2">
								<span class="sandpopup_roomtype" title="{{item.room_type_name}}">{{item.room_type_name}}</span>
							</div>
							<!-- <div class="col-md-7">
								<div class="SandBoxInnerDiv" [attr.booking_room_id] = 'item.booking_room_id' [attr.roomtype_id] = "item.room_type_id">
									<div class="reservation-overview horizontal">
										<div class="bookings clearfix" >
											<div class="state-halfhour booked unpaid booked-blue" style="z-index: 1;">
												<div class="booked-TInfo">
													<div style="padding: 12px 10px 10px 0px;"><i class="fas fa-caret-left"></i></div>
													<div><span>{{item.booking_guest}}</span><br>
													<i class="fas fa-male"></i><span>{{item.adult_count}}</span><i class="fas fa-child"></i><span>{{item.children_count}}</span><span class="booked-price">{{item.price}} €</span> </div>
												</div>
												<div class="unpaidInner payed"></div>
											</div>
										</div>
									</div>
								</div> 
							</div> -->
							<div class="col-md-7">
								<div style="cursor: pointer;" class="SandBoxInnerDiv" (click)="editSandBooking(item)" [ngbPopover]="sandboxBookingPopup" triggers="hover" [attr.booking_room_id] = 'item.booking_room_id' [attr.roomtype_id] = "item.room_type_id">
									<div class="reservation-overview horizontal">
										<div class="bookings clearfix" >
											<div class="state-halfhour booked unpaid booked-blue" style="z-index: 1;">
												<div class="booked-TInfo">
													<div style="padding: 12px 10px 10px 0px;"><i class="fas fa-caret-left"></i></div>
													<div><span>{{item.booking_guest}}</span><br>
													<i class="fas fa-male"></i><span>{{item.adult_count}}</span><i class="fas fa-child"></i><span>{{item.children_count}}</span><span class="booked-price">{{item.price}} €</span> </div>
												</div>
												<div class="unpaidInner payed"></div>
											</div>
										</div>
									</div>
									<!-- {{itemData | json}} -->
									<ng-template #sandboxBookingPopup>
										<div>
											<span class="tool-tip-heading"><i class="fas fa-user"></i> &nbsp;{{item.booking_guest}}</span> <br />
											<i class="fas fa-person-booth"></i> &nbsp; <span class="roomName{{item.room_options.id}}">{{item?.room_type_name}}</span><br />                        
											<i class="fas fa-clock"></i> &nbsp; <span>{{item.reservation_from | date: 'dd MMM'}} - {{item.reservation_to | date: 'dd MMM'}}</span> <br />                       
											<hr *ngIf="item.rooms" />
											<ul style="padding-left:17px" *ngIf="item.rooms">
												<li *ngFor = "let room of item.rooms">{{room}}</li>
											</ul>
											<hr *ngIf="item.accessories" />
											<div *ngIf="item.accessories">Accesories:</div>
											<ul style="padding-left:17px" *ngIf="item.accessories">
												<li *ngFor = "let addon of item.accessories">{{addon}}</li>
											</ul>
											<hr *ngIf="item?.price" />
	
											<div *ngIf="item?.price">Pricing:</div>
											<ul style="padding-left:17px" *ngIf="item.price">
												<li>Price: <b>€ {{item?.price}}</b> </li>
												<li>Tax: <b>€ {{item?.tax}}</b></li>
												<li>Accesories: <b>€ {{item?.extra_price }}</b></li>
												<li>VAT: <b>€ {{item?.vat }}</b></li>
												<li>Pending: <b>€ {{item?.pending}}</b></li>
												<li>Total: <b>€ {{item?.pending}}</b></li> 
											</ul>
										</div>
									</ng-template> 
								</div> 								
							</div>
							<div class="col-md-3">
								<div> 
									<select class="selectSandboxRoom" [attr.booking_room_id] = 'item.booking_room_id' (change)="selectRoomfromDropdown()">
										<option value="">Select</option>
										<option *ngFor="let item1 of item.room_options" value="{{item1.id}}">{{item1.name}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div class="row sandpopup_AutoAssign">
						<div class="col-md-12">
							<span (click)="autoassignRoom()">AutoAssign</span>
							<span class="assignRoom" (click)="assignRoom()">Assign</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div id="Temporary_closuerModal" class="modal" role="dialog"> 
		<div class="modal-dialog">
			<!-- Modal content-->
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" style="padding:0;" class="close" data-dismiss="modal" aria-label="Close" (click)="close_modal()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h4>Temporary Closuer</h4>
					<div class="temporary_closuer_inner">
						<div>If you need it specify the reason for cloasing.</div>
						<textarea class="form-control" [(ngModel)]="temporary_closure" placeholder="Maintenance,lock,etc."></textarea>
					</div>
				</div>
				<div class="DetailBottomDiv">
					<button type="button" (click)="save_temporary_closuer()" class="btn saveDetails">Save</button>
				</div>
			</div>
		</div>
	</div>
	
	<div id="RestrictionsModal" class="modal" role="dialog"> 
		<div class="modal-dialog" style="max-width: 750px;">
			<!-- Modal content-->
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" style="padding:0;" class="close" data-dismiss="modal" aria-label="Close" (click)="close_modal()">
						<span aria-hidden="true">&times;</span>
					</button>
					<h1>Add Restrictions</h1>
					<div style="margin-top: 15px">
						<h3>Specifies the range of dates</h3>
						<h5>Both dates included.</h5>
						<div class="row" style="margin-bottom: 15px">
							<div class="col-md-2">
								<span class="restrictionscal"><i class="far fa-calendar-alt"></i></span>
							</div>
							<div class="col-md-4">
								<label>Arrivel Date</label>
								<input type="date" class="newbookdates form-control" [(ngModel)]="arrivel_date" >
								<div class="common_error arrivel_date display_none">Arrivel Date is required</div>
							</div>
							<div class="col-md-4">
								<label>Departure Date</label>
								<input type="date" class="newbookdates form-control" [(ngModel)]="departure_date" >
								<div class="common_error departure_date display_none">Departure Date is required</div>
								<div class="common_error departure_date1 display_none">Departure Date must be greater.</div>
							</div>
						</div>
						<div class="Restrictions_Days">
							<h5>Do you want to apply this rate to specific days?</h5>
							<div class="row">
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="6">
									<span>Sunday</span>
								</div>
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="0">
									<span>Monday</span>
								</div>
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="1">
									<span>Tuesday</span>
								</div>
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="2">
									<span>Wednesday</span>
								</div>
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="3">
									<span>Thursday</span>
								</div>
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="4">
									<span>Firday</span>
								</div>
								<div class="col-md-12 applyedDays">
									<input type="checkbox" class="form-control" name="5">
									<span>Saturday</span>
								</div>
							</div>
							<div class="common_error restric_days display_none">Atleast one is required</div>
						</div>
						<div class="row" style="margin: 35px -12px;">
							<div class="col-md-8">
								<label>Acciones</label>
								<select class="form-control editInput" [(ngModel)]="restriction_action">
									<option value="">Select</option>
									<option value="minimum_stay">Minimum Stay</option>
									<option value="maximum_stay">Maximum Stay</option>
									<option value="shut_down">Shut Down</option>
									<option value="to_open">Open</option>
								</select>
								<div class="common_error restriction_action display_none">Action is required</div>
							</div>
						</div>
						<div class="row" style="margin-bottom: 30px;">
							<div class="col-md-4">
								<label>Days</label>
								<select class="form-control editInput" [(ngModel)]="restriction_date">
									<option value="">Select</option>
									<option *ngFor="let i of restriction_datearr" value="{{i}}">{{i}}</option>
								</select>
								<div class="common_error restriction_date display_none">Number of days are required</div>
							</div>
						</div>
						<div class="row cancellationPolicies">
							<div class="col-md-12">
								<label>Cancellation Policies</label>
								<div class="cancellationPDiv"><input type="checkbox" class="form-control" name="policy one"> <span>Web Free cancellation Room only</span></div><br>
								<div class="cancellationPDiv"><input type="checkbox" class="form-control" name="policy two"> <span>Web Non-refundable Room only</span></div><br>
								<div class="cancellationPDiv"><input type="checkbox" class="form-control" name="policy three"> <span>Otas Free cancellation Room only</span></div><br>
								<div class="cancellationPDiv"><input type="checkbox" class="form-control" name="policy four"> <span>Otas Non-refundable Room only</span></div><br>
							</div>
							<div class="common_error c_policies display_none">Atleast one is required</div>
						</div>
						<div class="row">
							<div class="col-md-4">
								<label>Release</label>
								<input type="number" class="editInput form-control" [(ngModel)]="restriction_release">
								<div class="common_error restriction_release display_none">Release is required</div>
							</div>
						</div>
					</div>
				</div>
				<div class="DetailBottomDiv">
					<button type="button" (click)="save_restrictions()" class="btn saveDetails">Save</button>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="noaccessModal" class="modal" role="dialog"> 
	<div class="modal-dialog" style="max-width: 750px;">
		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-body"><span aria-hidden="true" (click)="close_modal()">&times;</span><div class="noaccess">No Access For This Page</div></div>
		</div>
	</div>
</div>


<!-- noData Found -->
<!-- <div id="noDataModal" class="modal" role="dialog"> 
	<div class="modal-dialog" style="max-width: 750px;">
		<div class="modal-content">
			<div class="modal-body"><span aria-hidden="true" (click)="close_modal()">&times;</span><div class="noaccess">No Data Found</div></div>
		</div>
	</div>
</div> -->


<!-- Modal -->
<div class="modal fade" id="noDataModal" tabindex="-1" aria-labelledby="noDataModal" aria-hidden="true">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header bg-danger">
		  <h5 class="modal-title text-white" id="noDataModalLabel">Sorry!</h5>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
		  No Data Found
		</div>
		<div class="modal-footer">
		  <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
	  </div>
	</div>
  </div>



<button type="button" class="btn hiddenbutton" style="display:none" (click)="hiddenbutton()">Check</button>
<button type="button" class="btn changeRoombotton" style="display:none" (click)="changeRoom()">changeRoom</button>
<button type="button" class="btn addHotelbotton" style="display:none">addHotelbotton</button>
</div>