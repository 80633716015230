// tslint:disable:variable-name
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { environment } from '../../../../../environments/environment';
import { baseFilter } from 'src/app/_fake/fake-helpers/http-extenstions';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }

  protected http: HttpClient;
  httpHeaders: any;
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;
  constructor(http: HttpClient) {
    this.http = http;
    //let currentUser = this.auth.currentUserSubject.asObservable();
    let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    //console.log(localStorage.getItem('PMSChicstaysUserKey'));
    //console.log(token);
	// if(token == null)
    //   window.location.href = "/auth/login";
    //debugger;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
 
  }

  // CREATE
  // server should return the object with ID
  create(item: any): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    //debugger
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<any>(this.API_URL, item, this.httpHeaders).pipe(
      catchError(err => {
        if(err.status == '401')
            window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false)) 
    );
  }


  roomlockfn(item: any): Observable<any> {
    let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + token   
          })
      };
      //debugger
      this._isLoading$.next(true);
      this._errorMessage.next('');
      return this.http.post<any>(this.API_URL, item, this.httpHeaders).pipe(
        catchError(err => {
          if(err.status == '401')
              window.location.href = window.location.origin + '/auth/login';
          this._errorMessage.next(err);
          console.error('CREATE ITEM', err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
    }
  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<any>> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    //debugger;
    return this.http.get<any>(this.API_URL,this.httpHeaders).pipe(
      map((response: any) => {
        //debugger;
        const filteredResult = baseFilter(response.data, tableState);
        const result: TableResponseModel<any> = {
          items: filteredResult.items,
          total: filteredResult.total
        };
        return result;
      })
    );
  }

  getItemById(id: any): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}/edit`;
    return this.http.get<any>(url,this.httpHeaders).pipe(
      catchError(err => {
        if(err.status == '401')
            window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  get(): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}`;
    return this.http.get<any>(url,this.httpHeaders).pipe(
      catchError(err => {
        if(err.status == '401')
            window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('GET', err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  

  // UPDATE
  update(item: any, id: any = null, isPost = false): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    const url = `${this.API_URL}/${id ? id : item.id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    if(isPost) {
      return this.http.post(url, item, this.httpHeaders).pipe(
        catchError(err => {
          if(err.status == '401')
            window.location.href = window.location.origin + '/auth/login';
          this._errorMessage.next(err);
          console.error('UPDATE ITEM', item, err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
    } else {
        return this.http.put(url, item, this.httpHeaders).pipe(
          catchError(err => {
            if(err.status == '401')
              window.location.href = window.location.origin + '/auth/login';
            this._errorMessage.next(err);
            console.error('UPDATE ITEM', item, err);
            //return of(item);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );
    }
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/updateStatus';
    return this.http.put(url, body).pipe(
      catchError(err => {
        if(err.status == '401')
            window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: any): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url,this.httpHeaders).pipe(
      catchError(err => {
         //debugger;
        if(err.status == '401')
            window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/deleteItems';
    const body = { ids };
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        throw err;;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getList(): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL ;
	//console.log(this.httpHeaders);
    return this.http.get<any>(url,this.httpHeaders).pipe(
      catchError(err => {
        if(err.status == '401')
           window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('getList', err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getLocks(): Observable<any> {
    let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + token   
          })
      };
      this._isLoading$.next(true);
      this._errorMessage.next('');
      const url = this.API_URL ;
    //console.log(this.httpHeaders);
      return this.http.get<any>(url,this.httpHeaders).pipe(
        catchError(err => {
          if(err.status == '401')
             window.location.href = window.location.origin + '/auth/login';
          this._errorMessage.next(err);
          console.error('getList', err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
    }
  
    getwayData(item: any): Observable<any> {
      let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
        this.httpHeaders = {
          headers: new HttpHeaders(
            {
              'Authorization': 'Bearer ' + token   
            })
        };
        //debugger
        this._isLoading$.next(true);
        this._errorMessage.next('');
        return this.http.post<any>(this.API_URL, item, this.httpHeaders).pipe(
          catchError(err => {
            if(err.status == '401')
                window.location.href = window.location.origin + '/auth/login';
            this._errorMessage.next(err);
            console.error('CREATE ITEM', err);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );
      }
    


      getDailyRate(item: any): Observable<any> {
        let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
          this.httpHeaders = {
            headers: new HttpHeaders(
              {
                'Authorization': 'Bearer ' + token   
              })
          };
          //debugger
          this._isLoading$.next(true);
          this._errorMessage.next('');
          return this.http.post<any>(this.API_URL, item, this.httpHeaders).pipe(
            catchError(err => {
              if(err.status == '401')
                  window.location.href = window.location.origin + '/auth/login';
              this._errorMessage.next(err);
              console.error('CREATE ITEM', err);
              throw err;
            }),
            finalize(() => this._isLoading$.next(false))
          );
        }

  getLockdata(): Observable<any> {
    let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
      this.httpHeaders = {
        headers: new HttpHeaders(
          {
            'Authorization': 'Bearer ' + token   
          })
      };
      this._isLoading$.next(true);
      this._errorMessage.next('');
      const url = this.API_URL ;
    //console.log(this.httpHeaders);
      return this.http.get<any>(url,this.httpHeaders).pipe(
        catchError(err => {
          if(err.status == '401')
             window.location.href = window.location.origin + '/auth/login';
          this._errorMessage.next(err);
          console.error('getList', err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
    }
  

    


  autoComplete(searchText): Observable<any> {
	let token = localStorage.getItem('PMSChicstaysUserKey') != null ? JSON.parse(localStorage.getItem('PMSChicstaysUserKey')).token.auth_token : null;
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Authorization': 'Bearer ' + token   
        })
    };
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/' + (searchText ? searchText : 'a') ;

    return this.http.get<any>(url,this.httpHeaders).pipe(
      catchError(err => {
        if(err.status == '401')
           window.location.href = window.location.origin + '/auth/login';
        this._errorMessage.next(err);
        console.error('getList', err);
        throw err;
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }


  public fetch() {    
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          if(err.status == '401')
          window.location.href = window.location.origin + '/auth/login';
          this._errorMessage.next(err);
          // return of({
          //   items: [],
          //   total: 0
          // });
          throw err;
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }
}
