import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { CommonService } from '../../../_services';
import { CustomAdapter, CustomDateParserFormatter, getDateFromString } from '../../../../../_PMSChicstays/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertModalComponent } from '../../../alert/alert-modal.component';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-change-room-modal',
  styleUrls: ['./change-room-modal.component.scss'],
  templateUrl: './change-room-modal.component.html',

  providers: [ 
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class ChangeRoomModalComponent implements OnInit, OnDestroy {
  @Input() booking_room_id: any; 
  @Input() room_id: any;
  @Input() room_name: any;
  @Input() existing_price: any;
  @Input() rate_types: [];
  @Input() date_filter:any;

  isLoading$;
  changeRoom: any = {};
  bookingData: any = {};
  newprice: any = '';
  hotelID:any;
  dateFilter:any;
  private subscriptions: Subscription[] = [];
  constructor(
    private commonService: CommonService,
    private toastr: ToastrService,
    private fb: FormBuilder, public modal: NgbActiveModal,
    ) { 
    }

  ngOnInit(): void {

    // alert(this.date_filter)
    console.clear()
    this.isLoading$ = this.commonService.isLoading$;
    this.executeScripts();
	//console.log(this.booking_room_id, 'booking_room_idbooking_room_idbooking_room_id');
	//console.log(this.room_id, "room_idroom_id");
  //console.log(this.room_name, 'this.room_namethis.room_name'); 
  
	let self = this;
	if(this.rate_types){ 
		$(this.rate_types).each(function(key,val){
			if(key == 0){
				self.changeRoom.rate_type_id = val.id;
				self.newprice = val.price; 
			}
		});
	}
  }

	change_roomType(){
		//console.log(this.changeRoom.rate_type_id);
		let self = this;
		$(this.rate_types).each(function(key,val){
			if(val.id == self.changeRoom.rate_type_id){
				//console.log(val.price);
				self.newprice = val.price;
			}
		});
	}
	changePrice() { 
		//debugger;
		this.commonService.API_URL =  `${environment.apiUrl}/hotels/change-room-and-rate/${this.booking_room_id}`;
		let object = {
		  room_id: this.room_id,
		  rate_type_id: this.changeRoom.rate_type_id
		}
		this.commonService.create(object).pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}				
        this.modal.close();
				return err;
			})).subscribe(
		  response => {
        this.hotelID = response.booking.hotel_id;
        this.dateFilter = response.booking.reservation_from;
        this.commonService.getbookingFun(this.dateFilter);
      // getBookings(this.hotelID, this.room_id, this.dateFilter)
			this.modal.close(this.newprice);    
		  }
		);  
	}
	closepopup(){
		this.modal.close();    
	}
  keepSamePrice() {
    //debugger; 
    this.commonService.API_URL =  `${environment.apiUrl}/hotels/change-room/${this.booking_room_id}`;
    let object = {
      room_id: this.room_id,
      force: 1
    }
    this.commonService.create(object).pipe(
			catchError((err) => {
				if(err['error']['message'] == "" || err['error']['message'] == undefined) {
					this.toastr.error("Please try again after sometime");	
				}else {
					this.toastr.error(err['error']['message']); 
				}	
        this.modal.close();
				return err;
			})).subscribe(
      response => {
        // debugger;
        this.hotelID = response.booking.hotel_id;
        this.dateFilter = response.booking.reservation_from;
        this.commonService.getbookingFun(this.dateFilter);
			  this.modal.close(this.newprice);     
      }
    );
  }

  executeScripts(): void {
    $(document).ready(function(){
      $('.modal-dialog').css('margin-top','75px');
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

} 
