<app-splash-screen></app-splash-screen>
<router-outlet></router-outlet>
<input type="text" value="yes" style="display: none;" class="is_initialized">

<div class="copyright text-right p-3 pr-5"> <ul>
    <li><a href="http://">  <i class="fab fa-facebook-f"></i> </a></li>
    <li><a href="http://"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
    <li><a href="http://"><i class="fab fa-linkedin" aria-hidden="true"></i></a></li>
    <li><p> © Copyright Revroo {{cYear}}</p></li>
</ul>
</div> 

<div class="back_menu">
    <a style="cursor: pointer;" class="back_menu_btn" (click)= "backClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-square-fill" viewBox="0 0 16 16">
            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1z"/>
        </svg>
    </a>  
</div>


<!-- <input 
ngx-google-places-autocomplete 
#placesRef="ngx-places" 
class="form-control form-control-lg form-control-solid"> -->