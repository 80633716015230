import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

//const API_USERS_URL = `${environment.apiUrl}/users`;
const API_USERS_URL = `${environment.apiUrl}/auth/`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  httpHeaders: any;
  constructor(private http: HttpClient) {
    this.httpHeaders = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
      })
    };
   }


  login(email: string, password: string): Observable<any> {
    //debugger;    
      let object = {
        username: email,
        password: password
      }
    return this.http.post<any>(`${environment.apiUrl}/auth/signin`,   object,this.httpHeaders);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  validateOTP(otpModel: any): Observable<any> {
    //debugger;
    return this.http.post<boolean>(`${environment.apiUrl}/auth/verify`, otpModel, this.httpHeaders);
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}`, {
      headers: httpHeaders,
    });
  }
}
