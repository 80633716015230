import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../bookings/_services';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, of, OperatorFunction, Subscription } from 'rxjs';
import {FormArray,FormBuilder,FormControl,FormGroup,Validators,} from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';





declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  CategoryTemplateForm: FormGroup;

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  tasks:Object[];
  isLoading:any;
  task:any;
  task_val:any;
  category_name:any;
  tasks_data:any;
  tasks_id:any;
  delete_tasks_id:any;

  constructor(
    private commonService: CommonService,
    private exportfb: FormBuilder, 
    private modalService: NgbModal,
    private toastr: ToastrService,
    
  ) 
  {
    this.CategoryTemplateForm = this.exportfb.group({
      category_name: ['', Validators.required],

    });

  }


    ngOnInit(): void {
    
      this.gettask_list();
    
  }

     
  // get task_list for api
  gettask_list() 
  {
    this.isLoading$.next(true);
    // alert('hello');
		var comman_data = {
			myurl: `${environment.apiUrl}/tasks/task-categories`,
		};
    this.commonService.comman_service_funcation_get(comman_data).subscribe((response)=>{

        console.log(response);
        var id = response[0].id;
        console.log(id);
        this.tasks = response;
        console.log(this.tasks);

        this.isLoading$.next(false);
    });

  }

  // Add data task api
   task_save()
  {

    $('.common_error').addClass('display_none');
    if(!this.category_name){
			$('.category_name').removeClass('display_none');
			return false;
		}
            // loader enabled
        this.isLoading$.next(true);

        var comman_data ={
          myurl: `${environment.apiUrl}/tasks/task-categories`,
          category_name:this.category_name,
        };

        this.commonService.task_service_funcation(comman_data).subscribe((response)=>{
        // console.log(response,'dddddddddddd');
        this.toastr.success('Success!', 'Task category added successfully.');
        $('#TaskeModal').modal('hide');
          // loader disabled
        this.isLoading$.next(false);
          this.gettask_list();
        });

  }

  // Add Categories modal open
  Add_task() 
  {
      $('#TaskeModal').modal('show');
  }

   // close Categories and edit modal
   close_modal() 
   {
       $('#TaskeModal').modal('hide');
       $('#EditTaskeModal').modal('hide');
       $('#DeleteTaskeModal').modal('hide');
   }

  //  Edit Task category
  Edit_task(task_id)
  {

    // loader enabled
    this.isLoading$.next(true);

    var comman_data ={
      myurl: `${environment.apiUrl}/tasks/task-categories/${task_id}`,
    };
    this.commonService.comman_service_funcation_get(comman_data).subscribe((response)=>{
      // console.log(response,'dddddddddddd');

      this.tasks_data = response.category_name;
      this.tasks_id = response.id;

      // loader disabled

      this.isLoading$.next(false);
     
    });
    
    $('#EditTaskeModal').modal('show');

  }

  // update task category

  task_update()
  {


    // empty field condition
    $('.common_error').addClass('display_none');
    if(!this.tasks_data){
			$('.tasks_data').removeClass('display_none');
			return false;
		}
    // loader enabled
    this.isLoading$.next(true);

    var comman_data ={
      myurl: `${environment.apiUrl}/tasks/task-categories/${this.tasks_id}`,
      category_name:this.tasks_data,
    };
    this.commonService.task_service_funcation_put(comman_data).subscribe((response)=>{
      // console.log(response,'dddddddddddd');

      this.toastr.success('Success!', 'Task Update successfully.');

      $('#EditTaskeModal').modal('hide');

      // loader disabled

      this.isLoading$.next(false);
      this.gettask_list();
    
    });
    
    

  }
  //  Deleter Edit Task category
  Delete_Edit_task(task_id)
  {

    var comman_data ={
      myurl: `${environment.apiUrl}/tasks/task-categories/${task_id}`,
    };
    this.commonService.comman_service_funcation_get(comman_data).subscribe((response)=>{
      // console.log(response,'dddddddddddd');

      this.tasks_data = response.category_name;
      this.tasks_id = response.id;
    
    });
    
    $('#DeleteTaskeModal').modal('show');

  }

  // delete task

  Delete_task()
  {

     // loader enabled
    this.isLoading$.next(true);

    var comman_data ={
      myurl: `${environment.apiUrl}/tasks/task-categories/${this.tasks_id}`,
    };
    this.commonService.comman_service_funcation_delete(comman_data).subscribe((response)=>{
      // console.log(response,'dddddddddddd');

      this.toastr.error('Success!', 'Task Delete successfully.');
      $('#DeleteTaskeModal').modal('hide');

       // loader disabled
      this.isLoading$.next(false);
      this.gettask_list();
      
    
    });  
  }
}
